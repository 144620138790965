import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { DefaultProps } from '../../pages/App/App';
import './KPI.scss';

interface Props extends DefaultProps {
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

interface State {}

class KPIInfo extends React.Component<Props, State> {
  render() {
    const { t } = this.props;

    return (
      <Grid
        container
        item
        direction="row"
        rowSpacing="15px"
        justifyContent="space-around"
        alignItems="stretch"
        sx={{
          backgroundColor: "#66FFA3",
          borderRadius: "0px 0px 25px 25px",
        }}
      >
        <Grid container item md={4} xs={12} justifyContent="stretch" alignItems="stretch" sx={{ paddingTop: { md: "0 !important", sm: "30px !important", xs: "15px !important" } }}>
          <Grid item className="green_box" sx={{ margin: { xs: "0 15px", sm: "0 30px", md: "30px 15px 30px 30px" }, padding: { xs: "20px", lg: "30px" } }}>
            <Typography variant="subtitle1" component="p" sx={{ fontSize: "20px", lineHeight: "24px", padding: "0 0 10px 0" }}>
              {t("spotted.companies.kpi.infobox.title1")}
            </Typography>
            <Typography variant="body1" component="p" sx={{ fontSize: { lg: "18px", md: "16px", xs: "16px" }, lineHeight: { lg: "24px", md: "22px", xs: "22px" } }}>
              {t("spotted.companies.kpi.infobox.body1")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item md={4} xs={12} justifyContent="stretch" alignItems="stretch" sx={{ padding: { md: "0 !important" } }}>
          <Grid item className="green_box" sx={{ margin: { xs: "0 15px", sm: "0 30px", md: "30px 15px" }, padding: { xs: "20px", lg: "30px" } }}>
            <Typography variant="subtitle1" component="p" sx={{ fontSize: "20px", lineHeight: "24px", padding: "0 0 10px 0" }}>
              {t("spotted.companies.kpi.infobox.title2")}
            </Typography>
            <Typography variant="body1" component="p" sx={{ fontSize: { lg: "18px", md: "16px", xs: "16px" }, lineHeight: { lg: "24px", md: "22px", xs: "22px" } }}>
              {t("spotted.companies.kpi.infobox.body2")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          md={4}
          xs={12}
          justifyContent="stretch"
          alignItems="stretch"
          sx={{ padding: { md: "0 !important" }, paddingBottom: { md: "0 !important", sm: "30px !important", xs: "15px !important" } }}
        >
          <Grid item className="green_box" sx={{ margin: { xs: "0 15px", sm: "0 30px", md: "30px 30px 30px 15px" }, padding: { xs: "20px", lg: "30px" } }}>
            <Typography variant="subtitle1" component="p" sx={{ fontSize: "20px", lineHeight: "24px", padding: "0 0 10px 0" }}>
              {t("spotted.companies.kpi.infobox.title3")}
            </Typography>
            <Typography variant="body1" component="p" sx={{ fontSize: { lg: "18px", md: "16px", xs: "16px" }, lineHeight: { lg: "24px", md: "22px", xs: "22px" } }}>
              {t("spotted.companies.kpi.infobox.body3")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation("proactive")(KPIInfo);
