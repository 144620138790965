import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import {withTranslation} from 'react-i18next';
import logoSpotted from '../../assets/images/logo_spotted.svg';
import {DefaultProps} from '../../pages/App/App';
import VisibilityContainer from '../VisibilityContainer';
import './TrapezoidLeft.scss';

interface Props extends DefaultProps {
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
	backgroundColor?: string;
	backgroundImage: any;
	hasRightLogo: boolean;
}

interface State {
	isVisible: boolean;
}

class TrapezoidLeft extends React.Component<Props, State> {
	render() {
		const {children, backgroundImage, hasRightLogo} = this.props;

		let maxWidth = this.props.maxWidth;
		if (maxWidth === undefined) {
			maxWidth = "lg";
		}

		let backgroundColor = this.props.backgroundColor;
		if (backgroundColor === undefined) {
			backgroundColor = 'rgba(0, 0, 0, 0.3)';
		}

		return (
			<Grid container spacing={0} direction="row" justifyContent="flex-start" alignItems="center">
				<Grid item md={12} lg={12} className="trapezoid_left" justifyContent="center" alignItems="center" sx={{
					backgroundImage:     `url(${backgroundImage})`,
					backgroundColor:     backgroundColor,
					backgroundBlendMode: 'darken',
					backgroundSize:      {lg: "100% calc(100%)", md: "cover", sm: "cover", xs: "cover"},
					backgroundPosition:  {lg: "left", md: "center"},
					clipPath:            {lg: "url(#trapezoid_left_curved_clip)", xs: "url(#trapezoid_left_clip)"},
				}}>
					<VisibilityContainer maxWidth={false} transition="slide" slideDirection="up">
						<Container maxWidth={maxWidth} disableGutters>
							{children}
						</Container>
					</VisibilityContainer>
					<svg width="0" height="0">
						<defs>
							<clipPath id="trapezoid_left_curved_clip" clipPathUnits="objectBoundingBox">
								<path d="M 0 0 L 0.94 0.08 C 0.96 0.08 0.96 0.12 0.96 0.12 L 0.96 0.88 C 0.96 0.92 0.94 0.92 0.94 0.92 L 0 0.98 Z" />
							</clipPath>
						</defs>
					</svg>
					<svg width="0" height="0">
						<defs>
							<clipPath id="trapezoid_left_clip" clipPathUnits="objectBoundingBox">
								<path d="M 0,0
                    L 1, 0.05
                    L 1, 0.95
                    L 0, 1
                    Z" />
							</clipPath>
						</defs>
					</svg>
				</Grid>
				{hasRightLogo ? (
					<Box sx={{
						position: "absolute",
						left:     "96%",
						display:  {xs: "none", md: "none", lg: "block"},
					}}>
						<object type="image/svg+xml" data={logoSpotted} className="slogo" aria-label="logo" />
					</Box>
				) : null}
			</Grid>
		);
	}
}

export default withTranslation("proactive")(TrapezoidLeft);
