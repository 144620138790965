import {CircularProgress} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {HTMLAttributes, RefAttributes} from 'react';
import {Trans, withTranslation} from 'react-i18next';
import APIClient from '../../helpers/APIClient';
import APIError from '../../helpers/APIError';
import {ACCEPTED_FILE_EXTENSIONS, GENDER_OPTIONS, MAX_FILE_SIZE} from '../../helpers/Constants';
import {GetLanguageFromBrowser} from '../../helpers/LanguageHelpers';
import {FieldValidation, isEmpty, isValidEmail, isValidGender, isValidName, isValidPhone} from '../../helpers/ValidationHelpers';
import Logger from '../../logger';
import {JobApplication} from '../../models/JobApplication';
import SuccessResponse from '../../models/SuccessResponse';
import {DefaultProps} from '../../pages/App/App';
import ConfirmationView from '../ConfirmationView/ConfirmationView';
import ErrorDialog from '../ErrorDialog/ErrorDialog';
import FormDropZone from '../FormDropZone';
import FormSelectField from '../FormSelectField/FormSelectField';
import FormTextField from '../FormTextField/FormTextField';
import VisibilityContainer from '../VisibilityContainer';
import './ApplyForm.scss';

interface Props extends DefaultProps {
	offerId: string;
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
	onSuccess?: () => void;
	htmlAttr?: HTMLAttributes<any>;
	refAttr?: RefAttributes<any>;
	color?: string;
	title: string;
	subtitle: string;
}

interface State {
	application: JobApplication;
	validation: FormValidation;
	errorCode?: number;
	applicationSubmitted?: boolean;
	cvDocument?: File;
	isLoading: boolean;
}

interface FormValidation {
	filledFields: string[];
	gender?: FieldValidation;
	firstname?: FieldValidation;
	lastname?: FieldValidation;
	email?: FieldValidation;
	phone?: FieldValidation;
	professionalTitle?: FieldValidation;
	experience: [
		{
			filledFields: string[];
			jobtitle?: FieldValidation;
			proficiency?: FieldValidation;
			location?: FieldValidation;
			duration?: FieldValidation;
			skills?: FieldValidation;
			[key: string]: any;
		}
	];
	documents: FieldValidation[];
	isValid: boolean;

	[key: string]: any;
}

class ApplyForm extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			application: {
				gender:            "",
				firstname:         "",
				lastname:          "",
				email:             "",
				phone:             "",
				professionalTitle: "",
				experience:        [],
				documents:         [],
				language:          GetLanguageFromBrowser(),
			},
			validation:  {
				filledFields: [],
				experience:   [
					{
						filledFields: [],
					},
				],
				documents:    [],
				isValid:      false,
			},
			isLoading:   false,
		};
	}

	private handleCVDocument = (files: File[]) => {
		if (files.length !== 1) {
			this.setState({
				errorCode: 3000,
			});
			return;
		}

		this.updateDocument(0, files[0]);
	};

	private updateDocument = (index: number, file: File) => {
		Logger.debug(`Updating document ${index}: ${file.name}`);

		const application = this.state.application;
		let validation = this.state.validation;

		const reader = new FileReader();
		reader.onload = (e: any) => {
			const ext = file.name.split(".").pop() as string;
			if (ext === undefined || ACCEPTED_FILE_EXTENSIONS.indexOf(ext) === -1) {
				this.setState({
					errorCode: 3002,
				});
				return;
			}

			let base64Data = e.target.result.split(",").pop();
			if (base64Data.length > MAX_FILE_SIZE) {
				this.setState({
					errorCode: 3001,
				});
				return;
			}

			application.documents[index] = {
				filetype:   ext!,
				doctype:    "cv",
				base64data: base64Data,
			};

			validation.filledFields.push("cvDocument");
			validation = this.validateForm(application, validation);

			this.setState({
				application,
				validation,
				cvDocument: file,
			});
		};

		reader.onerror = () => {
			reader.abort();
			this.setState({
				errorCode: 3003,
			});
		};

		reader.readAsDataURL(file);
	};

	private updateApplication = (field: string, value: string) => {
		Logger.debug(`Updating ${field}: ${value}`);

		const application = this.state.application;
		let validation = this.state.validation;

		application[field] = value;

		if (validation.filledFields.indexOf(field) < 0) validation.filledFields.push(field);

		validation = this.validateForm(application, validation);

		this.setState({
			application,
			validation,
		});

		Logger.debug(application);
	};

	private validateForm = (application: JobApplication, validation: FormValidation, onlyFilledFields = true) => {
		let isValid = true;

		["gender", "firstname", "lastname", "email", "professionalTitle"].forEach((field) => {
			if (!onlyFilledFields || validation.filledFields.indexOf(field) !== -1) {
				let v = ApplyForm.validateField(field, application[field]);
				if (v.error != null) isValid = false;
				validation[field] = v;
			}
		});

		["phone"].forEach((field) => {
			if (!onlyFilledFields || validation.filledFields.indexOf(field) !== -1) {
				let v = ApplyForm.validateField(field, application[field], true);
				if (v.error != null) isValid = false;
				validation[field] = v;
			}
		});

		if (!onlyFilledFields || validation.filledFields.indexOf("cvDocument") !== -1) {
			if (application.documents.length < 1) {
				isValid = false;
				validation.documents[0] = {
					field: "cvDocument",
					error: "errors.validation.cv_document",
				};
			} else {
				validation.documents = [];
			}
		}

		validation.isValid = isValid;
		return validation;
	};

	private static validateField(field: string, value: string, allowEmpty: boolean = false) {
		let validation: FieldValidation = {
			field: field,
		};

		if (!allowEmpty && isEmpty(value)) validation.error = "errors.validation.empty";

		if (field === "gender") {
			if (!isValidGender(value)) validation.error = "errors.validation.gender";
		}

		if (field === "firstname" || field === "lastname") {
			if (!isValidName(value)) validation.error = "errors.validation.name";
		}

		if (field === "email") {
			if (!isValidEmail(value)) validation.error = "errors.validation.email";
		}

		if (field === "phone") {
			if (!isValidPhone(value)) validation.error = "errors.validation.phone";
		}

		return validation;
	}

	private submitApplication = () => {
		const application = this.state.application;
		const offerId = this.props.offerId;
		const isLoading = this.state.isLoading;

		if (isLoading) {
			return;
		}

		let validation = this.state.validation;
		validation = this.validateForm(application, validation, false);

		if (!validation.isValid) {
			this.setState({validation});
			return;
		}

		this.setState({
			isLoading: true,
		});

		Logger.debug(`Submitting application for offer ${offerId}...`);
		APIClient.postJobApplication(application, offerId)
			.then((response: SuccessResponse) => {
				Logger.info(`Successfully submitted job application: ${response.message}`);

				this.setState({
					applicationSubmitted: true,
					isLoading:            false,
				});

				if (this.props.onSuccess !== undefined) {
					this.props.onSuccess();
				}
			})
			.catch((error: APIError) => {
				Logger.error(`Failed to submit job application: ${JSON.stringify(error)}`);
				this.setState({
					errorCode: error.code,
					isLoading: false,
				});
			});
	};

	private dismissErrorDialog = () => {
		this.setState({
			errorCode: undefined,
		});
	};

	render() {
		const {t, htmlAttr, refAttr, title, subtitle} = this.props;
		const {application, validation, errorCode, applicationSubmitted, cvDocument, isLoading} = this.state;

		let maxWidth = this.props.maxWidth;
		if (maxWidth === undefined) {
			maxWidth = "lg";
		}

		const hasError = errorCode! > 0;

		return (
			<VisibilityContainer maxWidth={maxWidth} transition="slide" slideDirection="up">
				<form {...htmlAttr} {...refAttr} className="application-form" noValidate autoComplete="off">
					<Grid container sx={{padding: {xl: "0", lg: "0 80px", md: "0 60px", sm: "0 30px", xs: "0 15px"}}}>
						<Grid
							item
							xs={12}
							sx={{
								padding: {
									lg: "0 80px 40px",
									md: "0 60px 30px",
									sm: "0 30px 20px",
									xs: "0 15px 10px"
								},
							}}
						>
							{title !== undefined && (
								<Typography
									variant="h2"
									component="h2"
									sx={{
										fontSize:      {lg: "80px", md: "70px", xs: "50px"},
										lineHeight:    {lg: "75px", md: "65px", xs: "45px"},
										paddingBottom: "15px",
									}}
									dangerouslySetInnerHTML={{__html: title}}
								/>
							)}
							{subtitle !== undefined && (
								<Typography variant="body1" sx={{fontSize: "20px", lineHeight: "30px", padding: {xs: "0 0 30px 0"}}}>
									{subtitle}
								</Typography>
							)}
						</Grid>
						<Grid
							container
							item
							xs={12}
							sx={{
								backgroundColor: this.props.color,
								borderRadius:    "15px",
								padding:         {lg: "80px 80px", md: "40px 60px", sm: "30px", xs: "30px 15px"},
							}}
						>
							{applicationSubmitted &&
				  <ConfirmationView headline={t("apply_form.confirmation_title")} description={t("apply_form.confirmation_description")} />}
							{!applicationSubmitted && <Grid container alignContent="space-between" alignItems="center" direction="row">
				  <Grid item xs={12} className="application-form-field">
					  <FormSelectField
						  required={true}
						  name="gender"
						  value={application.gender}
						  onChange={this.updateApplication}
						  options={GENDER_OPTIONS}
						  validation={validation.gender}
					  />
				  </Grid>
				  <Grid item xs={12} className="application-form-field">
					  <FormTextField
						  required={true}
						  name="firstname"
						  label="apply_form.firstname"
						  value={application.firstname}
						  onChange={this.updateApplication}
						  autoComplete="given-name"
						  validation={validation.firstname}
					  />
				  </Grid>
				  <Grid item xs={12} className="application-form-field">
					  <FormTextField
						  required={true}
						  name="lastname"
						  label="apply_form.lastname"
						  value={application.lastname}
						  onChange={this.updateApplication}
						  autoComplete="family-name"
						  validation={validation.lastname}
					  />
				  </Grid>
				  <Grid item xs={12} className="application-form-field">
					  <FormTextField
						  required={true}
						  name="email"
						  label="apply_form.email"
						  value={application.email}
						  onChange={this.updateApplication}
						  autoComplete="email"
						  validation={validation.email}
					  />
				  </Grid>
				  <Grid item xs={12} className="application-form-field">
					  <FormTextField
						  required={false}
						  name="phone"
						  label="apply_form.phone"
						  value={application.phone}
						  textFieldProps={{inputProps: {type: "tel"}}}
						  onChange={this.updateApplication}
						  validation={validation.phone}
					  />
				  </Grid>
				  <Grid item xs={12} className="application-form-field">
					  <FormTextField
						  required={true}
						  name="professionalTitle"
						  label="apply_form.professionalTitle"
						  value={application.professionalTitle}
						  onChange={this.updateApplication}
						  validation={validation.professionalTitle}
					  />
				  </Grid>
				  <Grid item xs={12}>
					  <FormDropZone
						  required={true}
						  name="cvDocument"
						  label="apply_form.cvDocument"
						  value={cvDocument}
						  onChange={this.handleCVDocument}
						  validation={validation.documents[0]}
					  />
				  </Grid>
				  <Grid container item xs={12} className="application-form-field">
					  <p><Trans i18nKey="apply_form.tos_privacy_notice">
						  Mit dem Absenden dieses Formulars erklärst du dich mit den
								<a href="https://www.jobcloud.ch/c/de-ch/nutzungsbedingungen-spotted/" target="_blank" rel="noopener noreferrer">Nutzungsbedingungen für Spotted (Digital Recruiting by JobCloud)</a> einverstanden.
						  Unsere Datenschutzerklärung findest du <a href="https://www.jobcloud.ch/c/de-ch/datenschutzerklaerung-spotted/" target="_blank"
																	rel="noopener noreferrer">hier</a>.
					  </Trans></p>
				  </Grid>
				  <Grid item xs={12}>
					  <Button variant="contained" size="large" color="primary" onClick={this.submitApplication} disabled={isLoading} className="submitButton"
							  sx={{minWidth: {sm: "auto", xs: "100%"}, margin: "30px 0 0 0"}}>
												{isLoading && <CircularProgress size={24} color="secondary" />}
												{t("apply_form.submit")}
					  </Button>
				  </Grid>
			  </Grid>}
							<ErrorDialog code={errorCode ? errorCode : 0} show={hasError} color="secondary" onDismiss={this.dismissErrorDialog} />
						</Grid>
					</Grid>
				</form>
			</VisibilityContainer>
		);
	}
}

export default withTranslation("proactive")(ApplyForm);
