import * as React from "react";
import {PropsWithChildren} from "react";
import {Outlet, useLocation} from 'react-router-dom';
import FloatingTopButton from "../FloatingTopButton/FloatingTopButton";
import Footer from "../Footer/Footer";
import {NavigationMenu} from "../NavigationMenu/NavigationMenu";

interface Props extends PropsWithChildren<any> {
	navType: "companies" | "talents"
	ctaButtonAction: () => void
	ctaButtonText?: string
}

const Layout: React.FC<Props> = (props: Props) => {

	let location = useLocation();
	React.useEffect(() => {
		window.scrollTo({
			top:      0,
			left:     0,
			behavior: "smooth",
		});
	}, [location]);

	const topButtonColor = props.navType === "talents" ? "secondary" : "primary";
	const footerColor = props.navType === "talents" ? "#EEFF84" : "#66FFA3";

	return (
		<>
			<FloatingTopButton color={topButtonColor} />
			<NavigationMenu navType={props.navType} ctaButtonAction={props.ctaButtonAction} ctaButtonText={props.ctaButtonText} />
			<Outlet />
			<Footer color={footerColor} />
		</>
	);
};

export default Layout;
