import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import './index.scss';
import App from './pages/App/App';
import * as serviceWorker from './serviceWorker';
import MuiTheme from './theme';

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={MuiTheme}>
      <App />
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
