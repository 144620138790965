import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {withTranslation} from 'react-i18next';
import {DefaultProps} from '../../pages/App/App';
import VisibilityContainer from '../VisibilityContainer';
import './Services.scss';

interface Props extends DefaultProps {
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
	backgroundImage: any;
}

interface State {
}

class Services extends React.Component<Props, State> {
	render() {
		const {t, backgroundImage} = this.props;

		let maxWidth = this.props.maxWidth;
		if (maxWidth === undefined) {
			maxWidth = "lg";
		}

		return (
			<VisibilityContainer maxWidth={maxWidth} transition="slide" slideDirection="up">
				<Grid container item xs={12} sx={{padding: {xl: "0", lg: "0 80px", md: "0 60px", sm: "0 30px", xs: "0 15px"}}}>
					<Grid container spacing={0} direction="row" justifyContent="flex-start" alignItems="center" className="services" sx={{
						backgroundImage:     `url(${backgroundImage})`,
						backgroundColor:     "rgba(0, 0, 0, 0.3)",
						backgroundBlendMode: "darken",
					}}>
						<Grid container item sx={{
							padding: {
								lg: "80px 80px 0 80px",
								md: "60px 60px 0 60px",
								sm: "30px 30px 0 30px",
								xs: "30px 15px"
							}
						}}>
							<Grid item xs={12} sx={{padding: {md: "0 0 30px 0", xs: "15px"}}}>
								<Typography variant="h2" component="h2" sx={{
									color:      "#66FFA3",
									fontSize:   {lg: "80px", md: "70px", xs: "50px"},
									lineHeight: {lg: "75px", md: "65px", xs: "45px"},
								}} dangerouslySetInnerHTML={{__html: t("spotted.companies.services.title")}} />
							</Grid>
							<Grid item xs={12} sx={{padding: {md: "0 0 30px 0", xs: "0 15px 30px 15px"}}}>
								<Typography variant="body1" component="p" sx={{
									color:      "#FFFFFF",
									fontSize:   20,
									lineHeight: "30px"
								}}>
									{t("spotted.companies.services.body")}
								</Typography>
							</Grid>
						</Grid>
						<Grid container rowSpacing="15px" columnSpacing={{
							xs: 1, sm: 2, md: 3
						}} justifyContent="space-between" alignItems="stretch" sx={{padding: {sm: "0 30px", xs: "0 15px"}}}>
							<Grid container item justifyContent="space-between" alignItems="stretch" md={6} xs={12}>
								<Grid container item className="service_box" sx={{marginBottom: {sm: "30px", xs: "15px"}}}>
									<Grid container item justifyContent="space-between" alignItems="stretch">
										<Grid item xs={12}>
											<Grid container item justifyContent="center" alignItems="center">
												<Typography variant="subtitle1" component="p" className="service_number">
													1
												</Typography>
											</Grid>
											<Grid container item justifyContent="center" alignItems="center">
												<Typography variant="h4" component="h4" sx={{
													fontSize:   "40px",
													lineHeight: "40px",
													margin:     "26px 0"
												}}>
													{t("spotted.companies.services.type.title1")}
												</Typography>
											</Grid>
											<Grid item container justifyContent="center" alignItems="center" sx={{
												fontSize:     20,
												lineHeight:   "30px",
												marginBottom: "30px",
												padding:      {lg: "0 62px", md: "0"},
											}}>
												<Typography variant="body1" component="p" sx={{
													fontSize:   "20px",
													lineHeight: "30px",
												}}>
													{t("spotted.companies.services.type.body1")}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Grid container item justifyContent="space-between" alignItems="stretch" md={6} xs={12}>
								<Grid container item className="service_box" sx={{marginBottom: {sm: "30px", xs: "15px"}}}>
									<Grid container item justifyContent="space-between" alignItems="stretch">
										<Grid item xs={12}>
											<Grid container item justifyContent="center" alignItems="center">
												<Typography variant="subtitle1" component="p" className="service_number">
													2
												</Typography>
											</Grid>
											<Grid container item justifyContent="center" alignItems="center">
												<Typography variant="h4" component="h4" sx={{
													fontSize:   "40px",
													lineHeight: "40px",
													margin:     "26px 0"
												}}>
													{t("spotted.companies.services.type.title2")}
												</Typography>
											</Grid>
											<Grid container item justifyContent="center" alignItems="center" sx={{
												fontSize:     20,
												lineHeight:   "30px",
												marginBottom: "30px",
												padding:      {lg: "0 62px", md: "0"},
											}}>
												<Typography variant="body1" component="p" sx={{
													fontSize:   "20px",
													lineHeight: "30px",
												}}>
													{t("spotted.companies.services.type.body2")}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</VisibilityContainer>
		);
	}
}

export default withTranslation("proactive")(Services);
