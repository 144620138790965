import CssBaseline from '@mui/material/CssBaseline';
import {i18n, TFunction} from 'i18next';
import * as React from "react";
import {useEffect} from "react";
import {withTranslation} from 'react-i18next';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import {default as CompaniesPage} from '../Companies/LandingPage';
import {default as TalentsPage} from '../Talents/LandingPage';
import TeamPage from "../Team/TeamPage";

export interface DefaultProps {
	t: TFunction;
	i18n: i18n;
}

const App: React.FC<DefaultProps> = (props: DefaultProps) => {
	const t = props.t;

	const signupRef: React.RefObject<any> = React.createRef();
	const scrollToSignupRef = () => {
		window.scrollTo({
			top:      signupRef.current.offsetTop - 50,
			behavior: "smooth"
		});
	};

	useEffect(() => {
		if (window.location.hash === '#signup') {
			scrollToSignupRef();
		}
	});

	return (
		<BrowserRouter>
			<CssBaseline enableColorScheme />
			<Routes>
				<Route path="/" element={<Layout navType="companies" ctaButtonAction={scrollToSignupRef} />}>
					<Route index element={<CompaniesPage signupRef={signupRef} scrollToSignupRef={scrollToSignupRef} />} />
					<Route path="/companies">
						<Route index element={<CompaniesPage signupRef={signupRef} scrollToSignupRef={scrollToSignupRef} />} />
					</Route>
				</Route>
				<Route path="/team" element={<Layout navType="companies" ctaButtonAction={scrollToSignupRef} ctaButtonText={t('navigation.team_button')} />}>
					<Route index element={<TeamPage signupRef={signupRef} scrollToSignupRef={scrollToSignupRef} />} />
				</Route>
				<Route path="/talents" element={<Layout navType="talents" ctaButtonAction={scrollToSignupRef} />}>
					<Route index element={<TalentsPage signupRef={signupRef} scrollToSignupRef={scrollToSignupRef} />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default withTranslation("proactive")(App);
