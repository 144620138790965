import {Container, Slide} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {RefObject} from "react";
import {withTranslation} from 'react-i18next';
import ContactForm from "../../components/ContactForm/ContactForm";
import Divider from '../../components/Divider';
import Team from "../../components/Team/Team";
import VisibilityContainer from "../../components/VisibilityContainer";
import {DefaultProps} from '../App/App';

interface Props extends DefaultProps {
	signupRef: RefObject<any>;
	scrollToSignupRef: () => void
}

interface State {
}

class TeamPage extends React.Component<Props, State> {
	private readonly signupRef: React.RefObject<any>;
	private readonly scrollToSignupRef: () => void;

	constructor(props: Props) {
		super(props);
		this.signupRef = props.signupRef;
		this.scrollToSignupRef = props.scrollToSignupRef;
	}

	private hideFormContent = () => {
		this.scrollToSignupRef();
	};

	render() {
		const {t} = this.props;

		return (
			<React.Fragment>
				<Slide direction="up" in={true} timeout={900}>
					<div>
						<Divider />
						<VisibilityContainer maxWidth="lg" transition="slide" slideDirection="up">
							<Grid item xs={12} sx={{padding: {xl: "0 80px", lg: "0 160px", md: "0 120px", sm: "0 80px", xs: "0 30px"}}}>
								<Grid item>
									<Typography variant="h1" component="h1" sx={{
										fontSize:   {lg: "120px", md: "90px", xs: "65px"},
										lineHeight: {lg: "100px", md: "80px", xs: "60px"},
									}}>
										{t("spotted.team.section1.title")}
									</Typography>
								</Grid>
								<Grid item>
									<Typography variant="body1" component="div" sx={{
										fontSize:   20,
										lineHeight: "30px",
										padding:    "40px 0",
									}} dangerouslySetInnerHTML={{__html: t("spotted.team.section1.body")}} />
								</Grid>
							</Grid>
						</VisibilityContainer>
						<Divider size="small" />
						<Team />
						<Divider />
						<Container disableGutters ref={this.signupRef}>
							<ContactForm title={t("spotted.team.contact.title")} subtitle={t("spotted.team.contact.body")} submitButtonText={t("spotted.team.contact.button")} onSuccess={this.hideFormContent} color="#66FFA3" />
						</Container>
						<Divider />
					</div>
				</Slide>
			</React.Fragment>
		);
	}
}

export default withTranslation("proactive")(TeamPage);