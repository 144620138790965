import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {withTranslation} from 'react-i18next';
import {DefaultProps} from '../../pages/App/App';
import TrapezoidRight from "../Trapezoid/TrapezoidRight";

export interface Quote {
	quote: string
	name: string
	job: string
}

interface Props extends DefaultProps {
	quotes: Quote[];
	backgroundImage: any;
}

interface State {
}

class Quotes extends React.Component<Props, State> {
	render() {

		const backgroundImage = this.props.backgroundImage;

		const rand = Math.floor(Math.random() * this.props.quotes.length);
		const currentQuote = this.props.quotes[rand];

		const images = require.context('../../assets/images/quotes/', true);
		const quoteImages = images.keys().map(image => images(image));
		const currentImage = quoteImages[rand];

		return (
			<TrapezoidRight sideImage={currentImage} backgroundImage={backgroundImage}>
				<Grid container item xs={12} sx={{
					padding: {
						xl: "160px 80px",
						lg: "160px",
						md: "120px",
						sm: "60px",
						xs: "30px"
					},
				}}>
					<Grid item xs={12} sx={{
						padding: {
							xl: "120px 120px 0 0", lg: "120px 120px 0 0", md: "60px 60px 0 0", sm: "80px 30px 0 0", xs: "80px 15px 0 0"
						}
					}}>
						<blockquote>
							<Typography variant="h4" component="h4" sx={{
								color:      "#66FFA3",
								fontSize:   40,
								lineHeight: "40px",
							}} dangerouslySetInnerHTML={{__html: currentQuote.quote}} />
						</blockquote>
					</Grid>
					<Grid item xs={12} sx={{padding: {lg: "10px 0 120px 0 ", md: "10px 0 60px 0 ", sm: "10px 0 60px 0", xs: "10px 0 60px 0"}}}>
						<Typography variant="subtitle1" component="span" sx={{fontSize: "20px", lineHeight: "30px", color: "#FFFFFF"}}>
							{currentQuote.name},&nbsp;
						</Typography>
						<Typography variant="body1" component="span" sx={{fontSize: "20px", lineHeight: "30px", color: "#FFFFFF"}}>
							{currentQuote.job}
						</Typography>
					</Grid>
				</Grid>
			</TrapezoidRight>
		);
	}
}

export default withTranslation("proactive")(Quotes);
