const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
const nameRegex = /^[^0-9<>*=]+$/; //eslint-disable-line
const phoneRegex = /^\+?\d{9,21}$/; // Note: follows this regex https://github.com/jobcloud/application-service-api/blob/main/app/Mapping/Validation/ApplicationDefinitionValidationMapper.php#L486

export function isEmpty(value: any) {

	if (typeof value === "string")
		return (!value || value.trim().length < 1);

	if (typeof value === "number")
		return (!value || value < 1);

	if (Array.isArray(value))
		return (!value || value.length < 1);

	return !value;
}

export function isValidGender(gender: string) {
	return gender === 'male' || gender === 'female';
}

export function isValidEmail(email: string) {
	return emailRegex.test(String(email).toLowerCase());
}

export function isValidName(name: string) {
	return nameRegex.test(String(name).toLowerCase());
}

export function isValidPhone(phone: string) {

	let trimmed = phone.replace(/ /g, '');

	if (trimmed.length === 0)
		return true;

	return phoneRegex.test(trimmed);
}


export interface FieldValidation {
	field: string
	error?: string
}
