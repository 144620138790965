import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {withTranslation} from 'react-i18next';
import {DefaultProps} from '../../pages/App/App';
import VisibilityContainer from '../VisibilityContainer';
import './Team.scss';

interface Props extends DefaultProps {
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

interface State {
}

class Team extends React.Component<Props, State> {
	render() {
		const {t} = this.props;

		let maxWidth = this.props.maxWidth;
		if (maxWidth === undefined) {
			maxWidth = "lg";
		}

		// IMPORTANT: add new person with increased number ie. last image has number 00028 then add 00029.
		// Update translations file with name and department
		const images = require.context('../../assets/images/team/', true);
		const teamMemberImages = images.keys().map(image => images(image));

		let teamMembers = [];
		for (const i in teamMemberImages) {
			teamMembers.push({
				name: t("spotted.team.members." + i + ".name"),
				title: t("spotted.team.members." + i + ".title"),
				image: teamMemberImages[i],
				isPaddingElement: false,
			});
		}

		shuffle(teamMembers);

		const numTeamMembers = teamMembers.length;
		for (let i = 0; i < 4; i++) {
			teamMembers.push({
				name: teamMembers[i].name,
				title: teamMembers[i].title,
				image: teamMembers[i].image,
				isPaddingElement: true,
			});
		}

		return (
			<VisibilityContainer maxWidth={maxWidth} transition="slide" slideDirection="up">
				<Grid
					container
					spacing={0}
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					sx={{padding: {xl: "0", lg: "0 120px", md: "0 60px", sm: "0 30px", xs: "0 15px"}}}
				>
					<Grid container item alignItems="stretch" direction="row" sx={{marginBottom: "30px"}}
						  className="team">
						{teamMembers.map((member, i) => (
							<Grid key={i} item xs={12} sm={6} md={4} lg={3}>
								<Grid container item direction="column-reverse" className="team-member"
									  sx={{
										  backgroundImage: `url(${member.image})`,
										  display: {
												xs: member.isPaddingElement ? 'none' : 'flex',
												sm: member.isPaddingElement ? shouldShowPaddingElement(i, numTeamMembers,2) ? 'flex' : 'none' : 'flex',
												md: member.isPaddingElement ? shouldShowPaddingElement(i, numTeamMembers,3) ? 'flex' : 'none' : 'flex',
												lg: member.isPaddingElement ? shouldShowPaddingElement(i, numTeamMembers,4) ? 'flex' : 'none' : 'flex',
												xl: member.isPaddingElement ? shouldShowPaddingElement(i, numTeamMembers,4) ? 'flex' : 'none' : 'flex',
											}
									  }}>
									<Grid item>
										<Typography variant="h4" component="p"
													sx={{fontSize: "40px", lineHeight: "40px"}}>
											{member.name}
										</Typography>
										<Typography variant="body1" component="p"
													sx={{
														fontSize: {lg: "18px", md: "16px", xs: "16px"},
														lineHeight: {lg: "24px", md: "22px", xs: "22px"}
													}}>
											{member.title}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						))}
					</Grid>
				</Grid>
			</VisibilityContainer>
		);
	}
}

function shouldShowPaddingElement(idx: number, count: number, columns: number): boolean {
	const mod = count % columns;
	if (mod === 0) return false;

	const padding = columns - mod;
	return idx - count < padding;
}

// https://javascript.info/array-methods#shuffle-an-array
function shuffle(array: Array<any>) {
	for (let i = array.length - 1; i > 0; i--) {
		let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

		// swap elements array[i] and array[j]
		// we use "destructuring assignment" syntax to achieve that
		// you'll find more details about that syntax in later chapters
		// same can be written as:
		// let t = array[i]; array[i] = array[j]; array[j] = t
		[array[i], array[j]] = [array[j], array[i]];
	}
}

export default withTranslation("proactive")(Team);
