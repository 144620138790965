import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {withTranslation} from 'react-i18next';
import {DefaultProps} from '../../pages/App/App';
import './ErrorDialog.scss';

export interface Props extends DefaultProps {
	show: boolean
	code: number
	color?: "primary" | "secondary"
	onRetry?: any
	onDismiss?: any
}

class ErrorDialog extends React.Component<Props> {

	constructor(props: Props) {
		super(props);
		this.state = {
			dismissed: false
		}
	}

	handleRetry = () => {
		this.props.onRetry();
	};

	dismissDialog = () => {
		this.props.onDismiss();
	};

	render() {
		const {t, code, onRetry, show, onDismiss} = this.props;
		const hasActions = onRetry || onDismiss;
		const message = (code !== undefined ? t('errors.code.' + code) : '');

		let color = this.props.color;
		if (color === undefined) {
			color = "primary"
		}

		return (
			<div>
				<Dialog open={show} className="error_dialog">
					<DialogTitle>{ t('errors.generic') }</DialogTitle>
					<DialogContent>
						<DialogContentText color="primary">
							{ message }
						</DialogContentText>
					</DialogContent>
					{hasActions &&
						<DialogActions>
							{onRetry &&
							<Button onClick={this.handleRetry} color={color} autoFocus>
								{t('retry')}
							</Button>
							}
							{onDismiss &&
							<Button onClick={this.dismissDialog} color={color} autoFocus>
								{t('dismiss')}
							</Button>
							}
						</DialogActions>
					}
				</Dialog>
			</div>
		);
	}
}

export default withTranslation("proactive")(ErrorDialog);
