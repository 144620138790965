import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link as MuiLink } from '@mui/material';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import logoJobCloud from '../../assets/images/logo_jobcloud.svg';
import logoLinkedIn from '../../assets/images/logo_linkedin.svg';
import { DefaultProps } from '../../pages/App/App';
import './Footer.scss';

interface Props extends DefaultProps {
  color: string;
}

interface State {}

class Footer extends React.Component<Props, State> {
  render() {
    const { t, color } = this.props;
    const madeWithLove = (
      <Typography component="span" sx={{ fontSize: "16px", lineHeight: "24px" }}>
        <Trans i18nKey="footer.made">
          Made with&nbsp;&nbsp;
          <FavoriteIcon htmlColor={color} sx={{ verticalAlign: "text-bottom !important", fontSize: "22px" }} />
          &nbsp;&nbsp;by JobCloud
        </Trans>
      </Typography>
    );

    return (
      <Container maxWidth={false} className="footer" disableGutters>
        <Container maxWidth="lg" disableGutters sx={{ padding: { xl: "60px 0", lg: "60px", md: "40px", sm: "30px", xs: "15px" } }}>
          <Grid container direction="row" spacing={0} justifyContent="space-between" alignItems="center">
            <Grid item flexGrow={2} sx={{ display: { xs: "none", md: "inline-block" }, fontSize: "16px", lineHeight: "24px" }}>
              <Grid container>
                <Grid item flexBasis="fit-content">
                  © {new Date().getFullYear()}&nbsp;&nbsp;
                </Grid>
                <Grid item flexBasis="fit-content">
                  <img src={logoJobCloud} alt={t("spotted.jobcloud")} className="footer_logo" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item flexGrow={3} sx={{ display: { xs: "none", md: "block" } }}></Grid>
            <Grid item sx={{ margin: { xs: "20px 0 10px 0", md: "0" } }}>
              <Grid container>
                <Grid item sx={{ margin: { xs: "0 10px 0 0", md: "0 15px 0 0" } }}>
                  <Typography sx={{ fontSize: "16px", lineHeight: "24px" }}>
                    <MuiLink href="https://www.jobcloud.ch/c/de-ch/agb/" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none", color: color }}>
                      {t("footer.tos")}
                    </MuiLink>
                  </Typography>
                </Grid>
                <Grid item sx={{ margin: { xs: "0 10px", md: "0 15px 0 15px" } }}>
                  <Typography component="span" sx={{ fontSize: "16px", lineHeight: "24px" }}>
                    <MuiLink href="https://www.jobcloud.ch/c/de-ch/datenschutzerklarung/" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none", color: color }}>
                      {t("footer.pp")}
                    </MuiLink>
                  </Typography>
                </Grid>
                <Grid item sx={{ margin: { xs: "0 0 0 10px", md: "0 0 0 15px" } }}>
                  <Typography component="span" sx={{ fontSize: "16px", lineHeight: "22px" }}>
                    <MuiLink
                      href="https://ch.linkedin.com/company/spotted-by-jobs"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ textDecoration: "none", color: color }}
                    >
                      <img src={logoLinkedIn} alt={t("spotted.jobcloud")} className="footer_logo" />
                    </MuiLink>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item flexGrow={1} flexBasis="fit-content" sx={{ display: { xs: "none", md: "block" } }}>
              <Grid container justifyContent="flex-end">
                {madeWithLove}
              </Grid>
            </Grid>
            <Grid item xs={12} justifyContent="flex-start" alignItems="center" sx={{ display: { xs: "flex", md: "none" }, paddingTop: "60px" }}>
              {madeWithLove}
            </Grid>
            <Grid item xs={12} justifyContent="flex-start" alignItems="center" sx={{ display: { xs: "flex", md: "none" }, fontSize: "16px", lineHeight: "16px" }}>
              © {new Date().getFullYear()} {t("spotted.jobcloud")}
            </Grid>
            <Grid container item xs={12} justifyContent="flex-start" alignItems="center" sx={{ display: { xs: "flex", md: "none" }, paddingTop: "60px" }}>
              <img src={logoJobCloud} alt={t("spotted.jobcloud")} className="footer_logo" />
            </Grid>
          </Grid>
        </Container>
      </Container>
    );
  }
}

export default withTranslation("proactive")(Footer);
