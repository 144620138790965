import {ContactData} from "../models/ContactData";
import {APIErrorCode, default as APIError} from './APIError';
import Logger from '../logger';
import Globals from '../globals';
import {JobApplication} from '../models/JobApplication';
import {isEmpty} from './ValidationHelpers';

interface Response {
	status: number
	body: any
}

class _client {
	private readonly url: string;

	constructor(url: string) {
		this.url = url;
	}

	getJobOffer(offerId: string) {

		if (!_client.validateOfferId(offerId)) {
			return _client.rejectWithErrorCode(APIErrorCode.JobOfferNotFound);
		}

		let url = `${this.url}/offers/${offerId}`;
		return this.sendRequest(url, {
			method: "GET"
		})
			.then((response: Response) => {

				if (response.status !== 200) {
					let error = new APIError(response.body.errorCode, response.status);
					return Promise.reject(error);
				}

				return Promise.resolve(response.body);

			});
	}

	postJobApplication(application: JobApplication, offerId: string) {

		if (!_client.validateOfferId(offerId)) {
			return _client.rejectWithErrorCode(APIErrorCode.JobOfferNotFound);
		}

		if (!_client.validateJobApplication(application)) {
			return _client.rejectWithErrorCode(APIErrorCode.InvalidRequestBody);
		}

		let url = `${this.url}/applications`;
		return this.sendRequest(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(application)
		})
			.then((response: Response) => {

				if (response.status !== 200) {

					let error = new APIError(response.body.errorCode, response.status);
					return Promise.reject(error);
				}

				return Promise.resolve(response.body);

			});
	}

	typeahead(term: string, type?: string) {

		let url = `${this.url}/autocomplete/?term=${term}&type=${type}`;
		return this.sendRequest(url, {
			method: "GET"
		})
			.then((response: Response) => {

				if (response.status !== 200) {
					return _client.rejectWithErrorCode(response.body.errorCode);
				}

				return Promise.resolve(response.body);

			});
	}

	getWithdrawal(withdrawalId: string) {

		if (!_client.validateWithdrawalId(withdrawalId)) {
			return _client.rejectWithErrorCode(APIErrorCode.JobApplicationNotFound);
		}

		let url = `${this.url}/applications/${withdrawalId}/withdraw`;
		return this.sendRequest(url, {
			method: "GET"
		})
			.then((response: Response) => {

				if (response.status !== 200) {
					return _client.rejectWithErrorCode(response.body.errorCode);
				}

				return Promise.resolve(response.body);
			});
	}

	postWithdrawal(withdrawalId: string) {

		if (!_client.validateWithdrawalId(withdrawalId)) {
			return _client.rejectWithErrorCode(APIErrorCode.JobApplicationNotFound);
		}

		let url = `${this.url}/applications/${withdrawalId}/withdraw`;

		return this.sendRequest(url, {
			method: "DELETE",
			mode: "cors"
		})
			.then((response: Response) => {

				if (response.status !== 200) {
					return _client.rejectWithErrorCode(response.body.errorCode);
				}

				return Promise.resolve(response.body);
			});
	}

	sendB2BSignup(data: ContactData) {

		if (!_client.validateB2BSignupFormData(data)) {
			return _client.rejectWithErrorCode(APIErrorCode.InvalidRequestBody);
		}

		let url = `${this.url}/b2b-signup`;
		return this.sendRequest(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(data)
		})
			.then((response: Response) => {

				if (response.status !== 200) {
					let error = new APIError(response.body.errorCode, response.status);
					return Promise.reject(error);
				}

				return Promise.resolve(response.body);

			});
	}

	private sendRequest(url: string, options: RequestInit) {

		Logger.debug(`${options.method} ${url}`);
		return fetch(url, options).catch(() => {

			return _client.rejectWithErrorCode(APIErrorCode.NoConnection);

		}).then(async (response) => {

			if (response.status >= 500) {
				return _client.rejectWithErrorCode(APIErrorCode.ServerError);
			}

			return {
				status: response.status,
				body: await response.json()
			}
		})
	}

	private static validateOfferId(offerId: string) {

		if (typeof offerId !== "string") {
			return false;
		}

		return offerId.length >= 1;
	}

	private static validateWithdrawalId(withdrawalId: string) {
		return withdrawalId.length >= 1;
	}

	private static validateJobApplication(application: JobApplication) {

		if (isEmpty(application.gender)) {
			return false;
		}

		if (isEmpty(application.firstname)) {
			return false;
		}

		if (isEmpty(application.lastname)) {
			return false;
		}

		if (isEmpty(application.email)) {
			return false;
		}

		if (isEmpty(application.professionalTitle)) {
			return false;
		}

		if (isEmpty(application.experience) && isEmpty(application.documents)) {
			return false;
		}

		let error = false;
		application.experience.forEach((experience) => {

			if (isEmpty(experience.jobtitle))
				error = true;

			if (isEmpty(experience.proficiency))
				error = true;

			if (isEmpty(experience.location))
				error = true;

			if (isEmpty(experience.duration))
				error = true;

			if (isEmpty(experience.skills))
				error = true;
		});

		application.documents.forEach((document) => {

			if (isEmpty(document.doctype))
				error = true;

			if (isEmpty(document.base64data))
				error = true;

			if (isEmpty(document.filetype))
				error = true;
		});

		return !error;
	}

	private static validateB2BSignupFormData(data: ContactData) {

		if (isEmpty(data.gender)) {
			return false;
		}

		if (isEmpty(data.firstname)) {
			return false;
		}

		if (isEmpty(data.lastname)) {
			return false;
		}

		if (isEmpty(data.email)) {
			return false;
		}

		if (isEmpty(data.company)) {
			return false;
		}

		if (isEmpty(data.message)) {
			return false;
		}

		return true
	}

	private static rejectWithErrorCode(code: APIErrorCode) {
		return Promise.reject(
			new APIError(code, 500)
		)
	}
}

const APIClient = new _client(Globals.get("API_URL") as string);
export default APIClient;
