import Box from "@mui/material/Box";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import {withTranslation} from 'react-i18next';
import {DefaultProps} from '../../pages/App/App';
import './TrapezoidRight.scss';

interface Props extends DefaultProps {
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
	backgroundColor?: string;
	backgroundImage: any;
	sideImage?: any;
}

interface State {
}

class TrapezoidRight extends React.Component<Props, State> {
	render() {
		const {children, backgroundImage, sideImage} = this.props;

		let maxWidth = this.props.maxWidth;
		if (maxWidth === undefined) {
			maxWidth = "lg";
		}

		let backgroundColor = this.props.backgroundColor;
		if (backgroundColor === undefined) {
			backgroundColor = 'rgba(0, 0, 0, 0.3)';
		}

		let hasLeftImage = sideImage;

		return (
			<Grid container spacing={0} direction="row" justifyContent="flex-start" alignItems="center" sx={{
				marginTop: {lg: 0, xs: hasLeftImage ? '120px' : 0}
			}}>
				<Grid item xs={0} lg={hasLeftImage ? 1 : 0} />
				<Grid container item xs={12} lg={hasLeftImage ? 11 : 12} justifyContent="center" alignItems="center" sx={{
					position: "relative"
				}}>
					<Grid item xs={12} className="trapezoid_right" justifyContent="center" alignItems="center" sx={{
						backgroundImage:     `url(${backgroundImage})`,
						backgroundColor:     backgroundColor,
						backgroundBlendMode: "darken",
						backgroundSize:      {lg: "100% calc(100%)", md: "cover", sm: "cover", xs: "cover"},
						backgroundPosition:  {lg: "right", md: "center", xs: "center"},
						clipPath:            {
							lg: "url(#trapezoid_right_curved_clip)",
							xs: "url(#trapezoid_right_clip)",
						},
					}}>
						<Container maxWidth={maxWidth} disableGutters sx={{padding: {xs: "60px 0", lg: "0 0 0 100px"}}}>
							{children}
						</Container>
						<svg width="0" height="0">
							<defs>
								<clipPath id="trapezoid_right_curved_clip" clipPathUnits="objectBoundingBox">
									<path d="M 1,0
                      L 0.06, 0.08
                      C 0.04 0.08, 0.04 0.12, 0.04 0.12
                      L 0.04, 0.88
                      C 0.04 0.92, 0.06 0.92, 0.06 0.92
                      L 1,1
                      Z" />
								</clipPath>
							</defs>
						</svg>
						<svg width="0" height="0">
							<defs>
								<clipPath id="trapezoid_right_clip" clipPathUnits="objectBoundingBox">
									<path d="
                      M 1,0
                      L 0, 0.05
                      L 0, 0.95
                      L 1,1
                      Z" />
								</clipPath>
							</defs>
						</svg>
					</Grid>
					{hasLeftImage ? (
						<Box sx={{
							position: "absolute",
							left:     {lg: '4%'},
							top:      {xs: '0', lg: 'inherit'},
						}}>
							<Box sx={{
								marginTop:  {xs: '-50%', lg: 'inherit'},
								marginLeft: {lg: '-50%'}
							}}>
								<Box sx={{width: {xs: '300px', lg: '200px'}, height: {xs: '300px', lg: '200px'}}}>
									<img src={sideImage} className="side_image" alt="" />
								</Box>
							</Box>
						</Box>
					) : null}
				</Grid>
			</Grid>
		);
	}
}

export default withTranslation("proactive")(TrapezoidRight);
