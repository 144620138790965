import {Container, Slide} from "@mui/material";
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from "react";
import {RefObject} from "react";
import {withTranslation} from 'react-i18next';
import backgroundImage04 from '../../assets/images/background_04.jpg';
import backgroundImage05 from '../../assets/images/background_05.jpg';
import ApplyForm from '../../components/ApplyForm/ApplyForm';
import Divider from '../../components/Divider';
import TextSection from '../../components/TextSection/TextSection';
import TrapezoidLeft from '../../components/Trapezoid/TrapezoidLeft';
import TrapezoidRight from '../../components/Trapezoid/TrapezoidRight';
import Globals from '../../globals';
import {DefaultProps} from '../App/App';

interface Props extends DefaultProps {
	signupRef: RefObject<any>;
	scrollToSignupRef: () => void
	match?: any;
}

interface State {
	offerId: string;
}

class LandingPage extends React.Component<Props, State> {
	private readonly signupRef: React.RefObject<any>;
	private readonly scrollToSignupRef: () => void;

	constructor(props: Props) {
		super(props);

		this.signupRef = props.signupRef;
		this.scrollToSignupRef = props.scrollToSignupRef;

		let offerId = this.props?.match?.params?.offerId;
		if (offerId === undefined) {
			offerId = Globals.get("DEFAULT_OFFER_ID")!;
		}

		this.state = {
			offerId: offerId,
		};
	}

	private hideFormContent = () => {
		this.scrollToSignupRef();
	};

	render() {
		const {t} = this.props;
		const {offerId} = this.state;

		return (
			<React.Fragment>
				<Slide direction="up" in={true} timeout={900}>
					<div>
						<TrapezoidLeft backgroundImage={backgroundImage04} backgroundColor='rgba(0, 0, 0, 0.6)' hasRightLogo={true}>
							<Grid container item xs={12} sx={{padding: {xl: "160px 80px", lg: "160px", md: "120px", sm: "60px", xs: "30px"}}}>
								<Grid item xs={12}>
									<Typography
										variant="h1"
										component="h1"
										sx={{
											color:      "#EEFF84",
											fontSize:   {lg: "120px", md: "90px", xs: "65px"},
											lineHeight: {lg: "100px", md: "80px", xs: "60px"},
											padding:    {md: "30px 0 30px 0", sm: "50px 0 30px 0", xs: "70px 0 30px 0"}
										}}
										dangerouslySetInnerHTML={{__html: t("spotted.talents.section1.title")}}
									/>
								</Grid>
								<Grid item xs={12} sx={{padding: {xs: "0 0 30px 0"}}}>
									<Typography
										variant="body1"
										component="p"
										sx={{
											color:      "#FFFFFF",
											fontSize:   20,
											lineHeight: "30px",
											padding:    "30px 0",
										}}
										dangerouslySetInnerHTML={{__html: t("spotted.talents.section1.body")}}
									/>
								</Grid>
								<Grid item xs={12}>
									<ButtonBase
										className="whiteButton"
										onClick={this.scrollToSignupRef}
										sx={{
											"&:hover": {
												backgroundColor: "#EEFF84 !important",
											},
											margin:    "0 0 70px 0",
										}}
									>
										{t("spotted.talents.section1.button")}
									</ButtonBase>
								</Grid>
							</Grid>
						</TrapezoidLeft>
						<Divider />
						<TextSection title={t("spotted.talents.section2.title")} subtitle={t("spotted.talents.section2.body")} />
						<Divider />
						<TrapezoidRight backgroundImage={backgroundImage05}>
							<Grid container item xs={12} sx={{padding: {xl: "0", lg: "0 80px", md: "0 60px", sm: "0 30px", xs: "0 15px"}}}>
								<Grid container item xs={12}
											sx={{
												padding: {
													xl: "160px 80px 0 80px",
													lg: "160px 80px 0 80px",
													md: "120px 60px 0 60px",
													sm: "120px 30px 0 30px",
													xs: "120px 15px 0 15px"
												}
											}}>
									<Typography
										variant="h2"
										component="h2"
										sx={{
											color:      "#EEFF84",
											fontSize:   {lg: "80px", md: "70px", xs: "50px"},
											lineHeight: {lg: "75px", md: "65px", xs: "45px"},
											padding:    {md: "0 0 30px 0", xs: "15px"}
										}}
										dangerouslySetInnerHTML={{__html: t("spotted.talents.section3.title")}}
									/>
								</Grid>
								<Grid container item xs={12} sx={{padding: {xl: "0 80px", lg: "0 80px", md: "0 60px", sm: "0 30px", xs: "0 15px"}}}>
									<Typography
										variant="body1"
										component="p"
										sx={{
											color:      "#FFFFFF",
											fontSize:   20,
											lineHeight: "30px",
											padding:    {md: "0 0 30px 0", xs: "0 15px 30px 15px"}
										}}
									>
										{t("spotted.talents.section3.body")}
									</Typography>
								</Grid>
								<Grid container item rowSpacing="15px" columnSpacing={{xs: 1, sm: 2, md: 3}} justifyContent="space-between" alignItems="stretch"
											sx={{marginRight: "0px", padding: {md: "0 30px", sm: "0 30px", xs: "0 15px"}}}>
									<Grid container item xs={12} md={4}>
										<Grid container item direction="column" justifyContent="start" alignItems="flex-start" className="steps">
											<Grid item>
												<Typography variant="subtitle1" component="p" className="number">
													1
												</Typography>
											</Grid>
											<Grid item className="title">
												<Typography variant="h4" component="h4"
																		sx={{fontSize: {lg: "40px", md: "30px", xs: "20px"}, lineHeight: {lg: "40px", md: "30px", xs: "20px"}}}>
													{t("spotted.talents.section3.steps.title1")}
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="body1" component="p"
																		sx={{fontSize: {lg: "18px", md: "16px", xs: "16px"}, lineHeight: {lg: "24px", md: "22px", xs: "22px"}}}>
													{t("spotted.talents.section3.steps.body1")}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid container item xs={12} md={4} sx={{margin: {xs: "10px 0", md: "0"}}}>
										<Grid container item direction="column" justifyContent="center" alignItems="flex-start" className="steps">
											<Grid item>
												<Typography variant="subtitle1" component="p" className="number">
													2
												</Typography>
											</Grid>
											<Grid item className="title">
												<Typography variant="h4" component="h4"
																		sx={{fontSize: {lg: "40px", md: "30px", xs: "20px"}, lineHeight: {lg: "40px", md: "30px", xs: "20px"}}}>
													{t("spotted.talents.section3.steps.title2")}
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="body1" component="p"
																		sx={{fontSize: {lg: "18px", md: "16px", xs: "16px"}, lineHeight: {lg: "24px", md: "22px", xs: "22px"}}}>
													{t("spotted.talents.section3.steps.body2")}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid container item xs={12} md={4}>
										<Grid container item direction="column" justifyContent="center" alignItems="flex-start" className="steps">
											<Grid item>
												<Typography variant="subtitle1" component="p" className="number">
													3
												</Typography>
											</Grid>
											<Grid item className="title">
												<Typography variant="h4" component="h4"
																		sx={{fontSize: {lg: "40px", md: "30px", xs: "20px"}, lineHeight: {lg: "40px", md: "30px", xs: "20px"}}}>
													{t("spotted.talents.section3.steps.title3")}
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant="body1" component="p"
																		sx={{fontSize: {lg: "18px", md: "16px", xs: "16px"}, lineHeight: {lg: "24px", md: "22px", xs: "22px"}}}>
													{t("spotted.talents.section3.steps.body3")}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}
											sx={{
												padding: {
													xl: "0 80px 80px 80px",
													lg: "0 120px 60px 120px",
													md: "0 60px 60px 60px",
													sm: "0 30px 60px 30px",
													xs: "0 15px 60px 15px"
												}
											}}>
									<ButtonBase
										className="whiteButton"
										onClick={this.scrollToSignupRef}
										sx={{
											"margin":  "40px 0",
											"&:hover": {
												backgroundColor: "#EEFF84 !important",
											},
										}}
									>
										{t("spotted.talents.section1.button")}
									</ButtonBase>
								</Grid>
							</Grid>
						</TrapezoidRight>
						<Divider />
						<Container disableGutters ref={this.signupRef}>
							<ApplyForm
								title={t("spotted.talents.contact.title")}
								subtitle={t("spotted.talents.contact.body")}
								onSuccess={this.hideFormContent}
								offerId={offerId}
								color="#EEFF84"
							/>
						</Container>
						<Divider />
					</div>
				</Slide>
			</React.Fragment>
		);
	}
}

export default withTranslation("proactive")(LandingPage);