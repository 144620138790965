import {TableCell, TableRow} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {withTranslation} from "react-i18next";
import iconCheckmarkCircle from "../../assets/images/icon_checkmark_circle.svg";

const defaultBorderColor = 'rgba(75, 75, 75, 0.3)'
const defaultTextAlign = "left"

interface Props {
	feature: string
	products: (string | boolean)[]
	borderColor?: string
	hideBottomBorder?: boolean
	textAlign?: "left" | "right" | "center"
}

interface State {
}

class FeatureTableRow extends React.Component<Props, State> {
	render() {

		const feature = this.props.feature;
		const products = this.props.products;

		const borderColor = this.props.borderColor ? this.props.borderColor : defaultBorderColor;
		const textAlign = this.props.textAlign ? this.props.textAlign : defaultTextAlign;

		const hideBottomBorder = this.props.hideBottomBorder;

		return (
			<TableRow>
				<TableCell sx={{
					borderColor:       borderColor,
					borderBottomStyle: hideBottomBorder ? 'none' : 'solid',
					padding:           {lg: '15px 30px 15px 80px', md: '15px 30px 15px 60px', sm: '15px 30px', xs: '15px'},
					maxWidth:          {sm: 'inherit', xs: '93px'},
				}}>
					<Typography variant="body1" component="p" sx={{
						fontSize:   {lg: "18px", md: "18px", xs: "16px"},
						lineHeight: {lg: "24px", md: "22px", xs: "22px"},
					}} dangerouslySetInnerHTML={{__html: feature}} />
				</TableCell>
				{products.map((p: string | boolean, i: number) => (
					<TableCell sx={{
						borderColor:       borderColor,
						borderLeftWidth:   {xs: '1px'},
						borderLeftStyle:   {sm: 'none', xs: 'solid'},
						borderBottomStyle: hideBottomBorder ? 'none' : 'solid',
						padding:           (i === (products.length - 1)) ? {lg: '15px 80px 15px 30px', sm: '15px 60px 15px 30px', xs: '15px'} : {
							sm: '15px 30px', xs: '15px'
						},
						maxWidth:          {sm: 'inherit', xs: '93px'},
					}} key={i} align={textAlign}>
						{typeof p === 'boolean' && p && <object type="image/svg+xml" data={iconCheckmarkCircle} aria-label="gray circle with green check mark inside" />}
						{typeof p === 'boolean' && !p &&
				<Typography variant="body1" component="p" sx={{
									fontSize:   {lg: "18px", md: "18px", xs: "16px"},
									lineHeight: {lg: "24px", md: "22px", xs: "22px"},
								}}>-</Typography>
						}
						{typeof p === 'string' &&
				<Typography variant="body1" component="p" sx={{
									fontSize:   {lg: "18px", md: "18px", xs: "16px"},
									lineHeight: {lg: "24px", md: "22px", xs: "22px"},
								}} dangerouslySetInnerHTML={{__html: p}} />
						}
					</TableCell>
				))}
			</TableRow>
		);
	}
}

export default withTranslation("proactive")(FeatureTableRow);