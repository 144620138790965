import {North} from "@mui/icons-material";
import {Fab, Slide} from "@mui/material";
import * as React from "react";
import {useEffect} from "react";
import {withTranslation} from "react-i18next";
import "./FloatingTopButton.scss";

interface Props {
	color?: "primary" | "secondary"
}

function FloatingTopButton(props: Props) {
	const [showButton, setShowButton] = React.useState<boolean>(false);
	const {color} = props;

	const scrollToTop = () => {
		window.scrollTo({top: 0, behavior: "smooth"});
	};

	useEffect(() => {
		const checkScrollPosition = () => {
			if (window.scrollY > 300) {
				setShowButton(true);
			} else {
				setShowButton(false);
			}
		};
		window.addEventListener("scroll", checkScrollPosition);
	});

	const sxProps = {
		bottom: { xs: "15px", md: "45px" },
		right: { xs: "15px", md: "40px" }
	};

	return (
		<Slide direction="up" timeout={1000} in={showButton}>
			<Fab onClick={scrollToTop} variant="circular" color={color} sx={sxProps} className="floating-top-button" disableRipple disableFocusRipple disableTouchRipple>
				<North />
			</Fab>
		</Slide>
	);
}

export default withTranslation("proactive")(FloatingTopButton);