import {ChevronRight} from "@mui/icons-material";
import {Drawer, IconButton, List, ListItem, ListItemText, Slide} from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';
import {Trans} from 'react-i18next';
import {NavLink} from 'react-router-dom';
import iconMenu from '../../assets/images/icon_menu.svg';
import logoSpottedText from '../../assets/images/logo_spotted_text.svg';
import './NavigationMenu.scss';

interface NavigationMenuProps {
	navType: "companies" | "talents";
	ctaButtonAction: () => void;
	ctaButtonText?: string;
}

export const NavigationMenu: React.FC<NavigationMenuProps> = ({ctaButtonAction, navType, ctaButtonText}: NavigationMenuProps) => {
	const [showMobileMenu, setShowMobileMenu] = React.useState<boolean>(false);

	const openMobileMenu = () => {
		setShowMobileMenu(true);
	};

	const closeMobileMenu = () => {
		setShowMobileMenu(false);
	};

	const color = navType === "companies" ? "#66FFA3" : "#EEFF84";
	return (
		<AppBar position="static" sx={{color: "#484848", backgroundColor: "#F5F5F5", padding: {lg: "40px 0", md: "30px 0", xs: "20px 0"}}}>
			<Container disableGutters maxWidth="lg">
				<Toolbar disableGutters className="navigation_menu"
								 sx={{padding: {xl: "0 0 30px 0", lg: "0 60px 30px 60px", md: "0 40px 30px 40px", sm: "0 30px 30px 30px ", xs: "0 15px 30px 15px"}}}>
					<Slide in={true} direction="right" timeout={700}>
						<Box sx={{flexGrow: 1, display: "flex"}}>
							<NavLink end to="/" className="menu_logo_link">
								<object type="image/svg+xml" data={logoSpottedText} aria-label="Spotted by jobs.ch" className="menu_logo" />
							</NavLink>
						</Box>
					</Slide>
					<Box sx={{flexGrow: 3, display: "flex"}}></Box>
					<Slide in={true} direction="left" timeout={900}>
						<Box className="navigation_menu_responsive">
							{navType === "companies" ? (
								<>
									<Typography sx={{display: "block", padding: {xs: "10px", lg: "10px 15px"}}} align="center" className="menu_link" noWrap>
										<NavLink end to="/">
											<Trans i18nKey="navigation.home">Home</Trans>
										</NavLink>
									</Typography>
									<Typography sx={{display: "block", padding: {xs: "10px", lg: "10px 15px"}}} align="center" className="menu_link" noWrap>
										<NavLink end to="/team">
											<Trans i18nKey="navigation.team">Team</Trans>
										</NavLink>
									</Typography>
								</>
							) : null}
							<Typography sx={{minWidth: 100, padding: {xs: "10px", lg: "10px 15px"}}} align="center" className="menu_link" noWrap>
								<Button
									className="whiteButton"
									onClick={ctaButtonAction}
									sx={{
										"&:hover": {
											backgroundColor: color + " !important",
										},
									}}
								>
									{ctaButtonText !== undefined ? ctaButtonText :
										navType === "companies" ? <Trans i18nKey="navigation.contact_button">Angebot einholen</Trans> :
											<Trans i18nKey="navigation.apply_button">Jetzt Lebenslauf hochladen</Trans>
									}
								</Button>
							</Typography>
							{navType === "companies" ? (
								<Typography sx={{minWidth: 100, padding: {xs: "10px", lg: "10px 15px"}}} align="center" className="menu_link" noWrap>
									<NavLink end to="/talents">
										<Trans i18nKey="navigation.clients">Arbeitnehmer</Trans>
									</NavLink>
								</Typography>
							) : (
								<Typography sx={{minWidth: 100, padding: {xs: "10px", lg: "10px 15px"}}} align="center" className="menu_link" noWrap>
									<NavLink end to="/">
										<Trans i18nKey="navigation.companies">Arbeitgeber</Trans>
									</NavLink>
								</Typography>
							)}
						</Box>
					</Slide>
					<Box className="mobile_menu_responsive">
						<Slide in={true} direction="left" timeout={900}>
							<Button
								size="large"
								onClick={openMobileMenu}
								color="inherit"
								sx={{
									borderRadius:       "90px",
									padding:            "24px",
									backgroundImage:    `url(${iconMenu})`,
									backgroundPosition: "center",
									backgroundRepeat:   "no-repeat",
								}}
							></Button>
						</Slide>
						<Drawer open={showMobileMenu} onClose={closeMobileMenu} anchor="right"
										PaperProps={{className: "navigation_menu_mobile_wrapper"}} className="">
							<List className="mobile_menu">
								<ListItem className="mobile_menu_close">
									<IconButton size="large" onClick={closeMobileMenu}>
										<ChevronRight sx={{color: "white"}} />
									</IconButton>
								</ListItem>
								<ListItem className="mobile_menu_item">
									<NavLink end to="/" onClick={closeMobileMenu}>
										<ListItemText>
											<Trans i18nKey="navigation.mobile.companies">Arbeitgeber</Trans>
										</ListItemText>
									</NavLink>
								</ListItem>
								<ListItem className="mobile_menu_item" onClick={closeMobileMenu}>
									<NavLink end to="/team">
										<ListItemText>
											<Trans i18nKey="navigation.mobile.team">Team</Trans>
										</ListItemText>
									</NavLink>
								</ListItem>
								<ListItem className="mobile_menu_item" onClick={closeMobileMenu}>
									<NavLink end to="/talents">
										<ListItemText>
											<Trans i18nKey="navigation.mobile.talents">Arbeitnehmer</Trans>
										</ListItemText>
									</NavLink>
								</ListItem>
							</List>
						</Drawer>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
};
