import {Container, Slide} from "@mui/material";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import * as React from "react";
import {RefObject} from "react";
import {withTranslation} from 'react-i18next';
import backgroundImage01 from '../../assets/images/background_01.jpg';
import backgroundImage02 from '../../assets/images/background_02.jpg';
import backgroundImage03 from '../../assets/images/background_03.jpg';
import backgroundImage10 from '../../assets/images/background_10.jpg';
import Clients from '../../components/Clients/Clients';
import ContactForm from '../../components/ContactForm/ContactForm';
import Divider from '../../components/Divider';
import FeatureTable from "../../components/FeatureTable/FeatureTable";
import KPI from '../../components/KPI/KPI';
import Quotes from "../../components/Quotes/Quotes";
import Services from '../../components/Services/Services';
import Testimonials from "../../components/Testimonials/Testimonials";
import TextSection from '../../components/TextSection/TextSection';
import TrapezoidLeft from '../../components/Trapezoid/TrapezoidLeft';
import VisibilityContainer from "../../components/VisibilityContainer";
import {DefaultProps} from '../App/App';

interface Props extends DefaultProps {
	signupRef: RefObject<any>;
	scrollToSignupRef: () => void
}

interface State {
}

class LandingPage extends React.Component<Props, State> {
	private readonly signupRef: React.RefObject<any>;
	private readonly scrollToSignupRef: () => void;

	constructor(props: Props) {
		super(props);
		this.signupRef = props.signupRef;
		this.scrollToSignupRef = props.scrollToSignupRef;
	}

	private hideFormContent = () => {
		this.scrollToSignupRef();
	};

	render() {
		const {t} = this.props;

		return (
			<React.Fragment>
				<Slide direction="up" in={true} timeout={900}>
					<div>
						<TrapezoidLeft backgroundImage={backgroundImage01} hasRightLogo={true}>
							<Grid container item xs={12} sx={{
								padding: {
									xl: "160px 80px",
									lg: "160px",
									md: "120px",
									sm: "60px",
									xs: "30px"
								}
							}}>
								<Grid container item xs={12}>
									<Typography variant="h1" component="h1" sx={{
										color:      "#66FFA3",
										fontSize:   {
											lg: "120px",
											md: "90px",
											xs: "65px"
										},
										lineHeight: {
											lg: "100px",
											md: "80px",
											xs: "60px"
										},
										padding:    {
											md: "30px 0 30px 0",
											sm: "50px 0 30px 0",
											xs: "70px 0 30px 0"
										}
									}} dangerouslySetInnerHTML={{__html: t("spotted.companies.section1.title")}} />
								</Grid>
								<Grid container item xs={12} sx={{padding: {xs: "0 0 30px 0"}}}>
									<List dense={true} sx={{
										color:      "#F5F5F5",
										fontSize:   20,
										lineHeight: "30px",
										padding:    0
									}}>
										{(t("spotted.companies.section1.items") as string[]).map((item: string, i: number) => (
											<ListItem sx={{padding: "0px 30px"}} key={i}>
												<ListItemText sx={{
													listStyleType: "disc",
													display:       "list-item"
												}} disableTypography={true} primary={item} />
											</ListItem>
										))}
									</List>
								</Grid>
								<Grid container item xs={12}>
									<Button className="whiteButton" onClick={this.scrollToSignupRef} sx={{margin: "0 0 70px 0"}}>
										{t("spotted.companies.section1.button")}
									</Button>
								</Grid>
							</Grid>
						</TrapezoidLeft>
						<Divider />
						<TextSection title={t("spotted.companies.section2.title")} subtitle={t("spotted.companies.section2.body")} />
						<Divider />
						<KPI type="info" backgroundImage={backgroundImage02}>
							<Grid item sx={{
								padding: {
									md: "0 0 30px 0",
									xs: "15px"
								}
							}}>
								<Typography variant="h2" component="h2" sx={{
									color:      "#66FFA3",
									fontSize:   {
										lg: "80px",
										md: "70px",
										xs: "50px"
									},
									lineHeight: {
										lg: "75px",
										md: "65px",
										xs: "45px"
									}
								}} dangerouslySetInnerHTML={{__html: t("spotted.companies.kpi.title")}} />
							</Grid>
							<Grid item sx={{
								padding: {
									md: "0 0 60px 0",
									xs: "0 15px 30px 15px"
								}
							}}>
								<Typography variant="body1" component="p" sx={{
									color:      "#FFFFFF",
									fontSize:   20,
									lineHeight: "30px"
								}}>
									{t("spotted.companies.kpi.body")}
								</Typography>
							</Grid>
							<Grid container item justifyContent="space-evenly" alignItems="stretch" sx={{
								background:   "rgba(255, 255, 255, 0.8)",
								boxShadow:    "0px 0px 10px rgba(75, 75, 75, 0.3)",
								borderRadius: "15px",
							}}>
								<Grid item md={3} sm={6} xs={12} sx={{
									borderRight:  {
										sm: "1px solid rgba(75, 75, 75, 0.3)",
										xs: "none",
									},
									borderBottom: {
										lg: "none",
										sm: "1px solid rgba(75, 75, 75, 0.3)",
										xs: "1px solid rgba(75, 75, 75, 0.3)",
									},
								}}>
									<Grid container item direction="column" justifyContent="center" alignItems="center" sx={{
										padding:   {
											lg: "40px 16px",
											xs: "20px 16px"
										},
										textAlign: "center",
										color:     "#4B4B4B",
									}}>
										<Grid item>
											<Typography variant="h1" component="h1" sx={{
												fontSize:   {
													lg: "120px",
													md: "80px",
													xs: "65px"
												},
												lineHeight: {
													lg: "100px",
													md: "70px",
													xs: "60px"
												},
												padding:    "10px",
											}}>
												{t("spotted.companies.kpi.percentage.number1")} <span className="percent">%</span>
											</Typography>
										</Grid>
										<Grid item sx={{
											fontSize:   {
												lg: "18px",
												xs: "16px"
											},
											lineHeight: {
												lg: "24px",
												xs: "22px"
											},
											padding:    {
												lg: "20px 30px 0",
												xs: "20px 0 0"
											},
										}}>
											{t("spotted.companies.kpi.percentage.body1")}
										</Grid>
									</Grid>
								</Grid>
								<Grid item md={3} sm={6} xs={12} sx={{
									borderRight:  {
										md: "1px solid rgba(75, 75, 75, 0.3)",
										sm: "none",
										xs: "none",
									},
									borderBottom: {
										lg: "none",
										sm: "1px solid rgba(75, 75, 75, 0.3)",
										xs: "1px solid rgba(75, 75, 75, 0.3)",
									},
								}}>
									<Grid container item direction="column" justifyContent="center" alignItems="center" sx={{
										padding:   {
											lg: "40px 16px",
											xs: "20px 16px"
										},
										textAlign: "center",
										color:     "#4B4B4B",
									}}>
										<Grid item>
											<Typography variant="h1" component="h1" sx={{
												fontSize:   {
													lg: "120px",
													md: "80px",
													xs: "65px"
												},
												lineHeight: {
													lg: "100px",
													md: "70px",
													xs: "60px"
												},
												padding:    "10px",
											}}>
												{t("spotted.companies.kpi.percentage.number2")} <span className="percent">%</span>
											</Typography>
										</Grid>
										<Grid item sx={{
											fontSize:   {
												lg: "18px",
												xs: "16px"
											},
											lineHeight: {
												lg: "24px",
												xs: "22px"
											},
											padding:    {
												lg: "20px 30px 0",
												xs: "20px 0 0"
											},
										}}>
											{t("spotted.companies.kpi.percentage.body2")}
										</Grid>
									</Grid>
								</Grid>
								<Grid item md={3} sm={6} xs={12} sx={{
									borderRight:  {
										sm: "1px solid rgba(75, 75, 75, 0.3)",
										xs: "none",
									},
									borderBottom: {
										sm: "none",
										xs: "1px solid rgba(75, 75, 75, 0.3)",
									},
								}}>
									<Grid container item direction="column" justifyContent="center" alignItems="center" sx={{
										padding:   {
											lg: "40px 16px",
											xs: "20px 16px"
										},
										textAlign: "center",
										color:     "#4B4B4B",
									}}>
										<Grid item>
											<Typography variant="h1" component="h1" sx={{
												fontSize:   {
													lg: "120px",
													md: "80px",
													xs: "65px"
												},
												lineHeight: {
													lg: "100px",
													md: "70px",
													xs: "60px"
												},
												padding:    "10px",
											}}>
												{t("spotted.companies.kpi.percentage.number3")} <span className="percent">%</span>
											</Typography>
										</Grid>
										<Grid item sx={{
											fontSize:   {
												lg: "18px",
												xs: "16px"
											},
											lineHeight: {
												lg: "24px",
												xs: "22px"
											},
											padding:    {
												lg: "20px 30px 0",
												xs: "20px 0 0"
											},
										}}>
											{t("spotted.companies.kpi.percentage.body3")}
										</Grid>
									</Grid>
								</Grid>
								<Grid item md={3} sm={6} xs={12} sx={{
									borderRight:  "none",
									borderBottom: "none"
								}}>
									<Grid container item direction="column" justifyContent="center" alignItems="center" sx={{
										padding:   {
											lg: "40px 16px",
											xs: "20px 16px"
										},
										textAlign: "center",
										color:     "#4B4B4B",
									}}>
										<Grid item>
											<Typography variant="h1" component="h1" sx={{
												fontSize:   {
													lg: "120px",
													md: "80px",
													xs: "65px"
												},
												lineHeight: {
													lg: "100px",
													md: "70px",
													xs: "60px"
												},
												padding:    "10px",
											}}>
												{t("spotted.companies.kpi.percentage.number4")} <span className="percent">%</span>
											</Typography>
										</Grid>
										<Grid item sx={{
											fontSize:   {
												lg: "18px",
												xs: "16px"
											},
											lineHeight: {
												lg: "24px",
												xs: "22px"
											},
											padding:    {
												lg: "20px 30px 0",
												xs: "20px 0 0"
											},
										}}>
											{t("spotted.companies.kpi.percentage.body4")}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</KPI>
						<Divider />
						<Clients />
						<Divider size="large" />
						<Testimonials testimonials={t("spotted.companies.testmonials")} />
						<Divider />
						<Services backgroundImage={backgroundImage03} />
						<Divider size="large" />
						<VisibilityContainer>
							<Grid container item xs={12} sx={{
								padding: {
									xl: "0",
									lg: "0 80px",
									md: "0 60px",
									sm: "0",
									xs: "0"
								}
							}}>
								<Grid item xs={12} sx={{
									padding: {
										xl: "0 80px",
										lg: "0 80px",
										md: "0 60px",
										sm: "0 60px",
										xs: "0 30px"
									}
								}}>
									<Typography variant="h2" component="h2" sx={{
										fontSize:   {
											lg: "80px",
											md: "70px",
											xs: "50px"
										},
										lineHeight: {
											lg: "75px",
											md: "65px",
											xs: "45px"
										},
									}} dangerouslySetInnerHTML={{__html: t("spotted.companies.pricing.title")}} />
								</Grid>
								<Grid item xs={12} sx={{
									padding: {
										xl: "0 80px",
										lg: "0 80px",
										md: "0 60px",
										sm: "0 60px",
										xs: "0 30px"
									}
								}}>
									<Typography variant="body1" component="div" sx={{
										fontSize:   {
											md: "20px",
											xs: "18px"
										},
										lineHeight: {
											md: "30px",
											xs: "26px"
										},
										paddingTop: "25px",
									}} dangerouslySetInnerHTML={{__html: t("spotted.companies.pricing.body")}}></Typography>
								</Grid>
								<Grid item xs={12} sx={{
									padding: {
										md: "30px 0",
										sm: "30px 0",
										xs: "15px 0"
									}
								}}>
									<FeatureTable products={t("spotted.companies.pricing.products")} features={t("spotted.companies.pricing.features")} textAlign="center" />
								</Grid>
								<Grid item xs={12} sx={{
									padding: {
										xl: "0 80px",
										lg: "0 80px",
										md: "0 60px",
										sm: "0 60px",
										xs: "0 30px"
									}
								}}>
									{(t("spotted.companies.pricing.footnotes") as string[]).map((note: string, i: number) => (
										<Typography variant="body1" component="p" sx={{
											fontSize:   {md: 16, xs: 14},
											lineHeight: "25px"
										}} key={i}>
											<sup>{i + 1}</sup> {note}
										</Typography>
									))}
								</Grid>
								<Grid item xs={12} sx={{
									padding: {
										xl: "0 80px",
										lg: "0 80px",
										md: "0 60px",
										sm: "0 60px",
										xs: "0 30px"
									}
								}}>
									<Button className="whiteButton" onClick={this.scrollToSignupRef} sx={{
										margin: {
											md: "60px 0",
											xs: "60px 15px"
										}
									}}>
										{t("spotted.companies.pricing.button")}
									</Button>
								</Grid>
							</Grid>
						</VisibilityContainer>
						<Divider />
						<Quotes quotes={t("spotted.companies.quotes")} backgroundImage={backgroundImage10} />
						<Divider />
						<Container disableGutters ref={this.signupRef}>
							<ContactForm title={t("spotted.companies.contact.title")} subtitle={t("spotted.companies.contact.body")} submitButtonText={t("spotted.companies.contact.button")} onSuccess={this.hideFormContent} color="#66FFA3" />
						</Container>
						<Divider />
						<TextSection backgroundImage={backgroundImage03} slideDirection="up" title={t("spotted.companies.section3.title")} subtitle={t("spotted.companies.section3.body")} button={t("spotted.companies.section3.action")} buttonPath="/team" />
					</div>
				</Slide>
			</React.Fragment>);
	}
}

export default withTranslation("proactive")(LandingPage);
