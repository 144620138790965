import {Box} from "@mui/material";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import {withTranslation} from 'react-i18next';
import {DefaultProps} from '../../pages/App/App';
import VisibilityContainer from '../VisibilityContainer';

const defaultMaxWidth = "lg"

export interface Testimonial {
	quote: string
	name: string
	company: string
}

interface Props extends DefaultProps {
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
	testimonials: Testimonial[];
}

interface State {
}

class Testimonials extends React.Component<Props, State> {
	render() {

		const maxWidth = this.props.maxWidth ? this.props.maxWidth : defaultMaxWidth;

		const rand = Math.floor(Math.random() * this.props.testimonials.length);
		const currentTestimonial = this.props.testimonials[rand];

		const images = require.context('../../assets/images/testimonials/', true);
		const testimonialImages = images.keys().map(image => images(image));
		const currentImage = testimonialImages[rand];

		return (
			<VisibilityContainer maxWidth={maxWidth} transition="slide" slideDirection="up">
				<Grid container spacing={0} direction="row" justifyContent="flex-start" alignItems="center" sx={{padding: {md: "60px 0", xs: "30px 0"}}}>
					<Grid item xs={0} md={1} lg={1}></Grid>
					<Grid item container direction="column" xs={12} md={10} lg={10} justifyContent="center" alignItems="center" sx={{
						padding: {
							sm: "0 30px", xs: "0 15px"
						}
					}}>
						<Grid item>
							<Typography variant="h2" component="h2" sx={{
								fontSize:     {lg: "80px", md: "70px", xs: "50px"},
								lineHeight:   {lg: "75px", md: "65px", xs: "45px"},
								textAlign:    "center",
								marginBottom: "60px"
							}} dangerouslySetInnerHTML={{__html: currentTestimonial.quote}} />
						</Grid>
						<Grid item>
							<Box sx={{
								width:              "200px",
								height:             "120px",
								backgroundImage:    `url(${currentImage})`,
								backgroundSize:     "contain",
								backgroundRepeat:   "no-repeat",
								backgroundPosition: "center",
							}}></Box>
						</Grid>
						<Grid item>
							<Typography variant="body1" sx={{fontSize: "18px", lineHeight: "26px", marginTop: "15px", textAlign: "center"}}>
								<strong>{currentTestimonial.name},</strong> {currentTestimonial.company}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={0} md={1} lg={1}></Grid>
				</Grid>
			</VisibilityContainer>
		);
	}
}

export default withTranslation("proactive")(Testimonials);
