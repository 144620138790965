import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { withTranslation } from 'react-i18next';
import { DefaultProps } from '../../pages/App/App';
import './ConfirmationView.scss';

interface Props extends DefaultProps {
	headline: string
	description: string
}

interface State {
}

class ConfirmationView extends React.Component<Props, State> {

	render() {
		return (
			<Card className="confirmation">
				<CardContent>
					<Typography variant="h5" component="h2" className="title" align="center">
						{this.props.headline}
					</Typography>
					<Typography variant="body1" component="p" className="description" align="center" paragraph>
						{this.props.description}
					</Typography>
				</CardContent>
			</Card>
		)
	}
}

export default withTranslation('proactive')(ConfirmationView);
