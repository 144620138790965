import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {withTranslation} from "react-i18next";
import FeatureTableRow from "./FeatureTableRow";

const defaultBorderColor = 'rgba(75, 75, 75, 0.3)'
const defaultTextAlign = "left"

export interface Feature {
	label: string
	values: (string | boolean)[]
}

interface Props {
	products: string[]
	features: Feature[]
	borderColor?: string
	textAlign?: "left" | "right" | "center"
}

interface State {
}

class FeatureTable extends React.Component<Props, State> {
	render() {

		const products = this.props.products;
		const features = this.props.features;

		const borderColor = this.props.borderColor ? this.props.borderColor : defaultBorderColor;
		const textAlign = this.props.textAlign ? this.props.textAlign : defaultTextAlign;

		return (
			<TableContainer component={Paper} sx={{background: "none", padding: {md: '30px 0', sm: '15px 30px', xs: '30px 0'}}}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={{
								borderColor: borderColor,
								padding:     {md: '15px 60px', sm: '15px 30px', xs: '15px'},
								maxWidth:    {sm: 'inherit', xs: '93px'},
							}}></TableCell>
							{products.map((p: string, i: number) => (
								<TableCell sx={{
									borderColor:     borderColor,
									borderLeftWidth: {xs: '1px'},
									borderLeftStyle: {sm: 'none', xs: 'solid'},
									padding:         (i === (products.length - 1)) ? {lg: '15px 80px 15px 30px', sm: '15px 60px 15px 30px', xs: '15px'} : {
										sm: '15px 30px', xs: '15px'
									},
								}} key={i} align={textAlign}>
									<Box sx={{display: "flex", justifyContent: "center"}}>
										<Typography variant="h5" component="h3" sx={{
											fontSize:    {lg: "40px", md: "30px", xs: "25px"},
											lineHeight:  {lg: "50px", md: "40px", xs: "30px"},
											writingMode: {sm: "inherit", xs: "vertical-lr"},
											transform:   {sm: "inherit", xs: "rotate(180deg)"},
											height:      {sm: "inherit", xs: "200px"},
										}} dangerouslySetInnerHTML={{__html: p}} />
									</Box>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{features.map((f: Feature, i: number) => (
							<FeatureTableRow feature={f.label} products={f.values} hideBottomBorder={i === (features.length - 1)} textAlign={textAlign} key={i} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	}
}

export default withTranslation("proactive")(FeatureTable);