import {Box, Button, Typography, useMediaQuery, useTheme} from '@mui/material';
import Carousel, {carouselClasses} from 'mui-carousel/Carousel';
import * as React from 'react';
import {withTranslation} from 'react-i18next';
import logoCustomer1 from '../../assets/images/customer_1.png';
import logoCustomer2 from '../../assets/images/customer_2.png';
import logoCustomer3 from '../../assets/images/customer_3.png';
import logoCustomer4 from '../../assets/images/customer_4.png';
import logoCustomer5 from '../../assets/images/customer_5.png';
import logoCustomer6 from '../../assets/images/customer_6.png';
import logoCustomer7 from '../../assets/images/customer_7.png';
import logoCustomer8 from '../../assets/images/customer_8.png';
import {DefaultProps} from '../../pages/App/App';
import VisibilityContainer from '../VisibilityContainer';
import './Clients.scss';

interface Props extends DefaultProps {
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

function Clients(props: Props) {
	const {t} = props;

	let maxWidth = props.maxWidth;
	if (maxWidth === undefined) {
		maxWidth = "lg";
	}

	const logos = [logoCustomer2, logoCustomer3, logoCustomer6, logoCustomer4, logoCustomer7, logoCustomer5, logoCustomer1, logoCustomer8];
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<VisibilityContainer maxWidth={maxWidth} transition="slide" slideDirection="up">
			<Typography
				variant="h3"
				component="h3"
				sx={{
					fontSize:   {lg: "55px", md: "40px", xs: "30px"},
					lineHeight: {lg: "50px", md: "40px", xs: "30px"},
					padding:    {xl: "0 0 35px 80px", lg: "0 0 35px 160px", md: "0 0 35px 120px", sm: "0 0 35px 60px", xs: "0 0 35px 35px"},
				}}
				dangerouslySetInnerHTML={{__html: t("spotted.companies.clients.title")}}
			/>
			<Carousel
				renderPrev={({disabled}) => (
					<Button
						disabled={disabled}
						disableRipple
						className="carousel-button prev"
						sx={{
							padding:   0,
							minWidth:  {xs: "30px", md: "60px"},
							minHeight: "100px",
							maxHeight: "100px",
						}}
					/>
				)}
				renderNext={({disabled}) => (
					<Button
						disabled={disabled}
						disableRipple
						className="carousel-button next"
						sx={{
							padding:   0,
							minWidth:  {xs: "30px", md: "60px"},
							minHeight: "100px",
							maxHeight: "100px",
						}}
					/>
				)}
				dots={false}
				showSlides={isSmallScreen ? 1 : 3}
				speed={2000}
				spacing={isSmallScreen ? 1 : 5}
				autoPlay={true}
				pauseOnHover={true}
				infinity
				centerMode
				disableTransition={false}
				className="carousel"
				sx={{
					[`& .${carouselClasses.item}`]:       {
						padding:    `${isSmallScreen ? "10px 20px !important" : ""}`,
						transition: "transform 1s ease !important",
					},
					[`& .${carouselClasses.item} > *`]:   {
						transition: "transform 1s ease",
					},
					[`& .${carouselClasses.center} > *`]: {
						transform: `${isSmallScreen ? "scale(1.0)" : "scale(1.2)"}`,
					}
				}}
			>
				{logos.map((logo, i) => (
					<Box key={i} className={`carousel-item carousel-item-${i + 1}`}>
						<Box className="carousel-item-logo" sx={{backgroundImage: `url(${logo});`}} />
					</Box>
				))}
			</Carousel>
		</VisibilityContainer>
	);
}

export default withTranslation("proactive")(Clients);
