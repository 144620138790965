import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import Globals from './globals';
import translations from './locales';

const debug = Globals.get("ENVIRONMENT") !== "production";

i18n.use(initReactI18next).init({
  resources: translations,
  fallbackLng: "de",
  lng: "de",
  debug: debug,

  // have a common namespace used around the full app
  ns: ["proactive"],
  defaultNS: "proactive",

  returnObjects: true,

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },
});

export default i18n;
