import TextField, {TextFieldProps} from '@mui/material/TextField';
import * as React from 'react';
import {ChangeEvent} from 'react';
import {withTranslation} from 'react-i18next';
import {FieldValidation} from '../../helpers/ValidationHelpers';
import {DefaultProps} from '../../pages/App/App';

interface Props extends DefaultProps {
	id?: string
	name: string
	label?: string
	required: boolean
	value: string
	autoComplete?: string
	rows?: number
	onChange: any
	validation?: FieldValidation
	textFieldProps?: TextFieldProps
}

interface State {
}

class FormTextArea extends React.Component<Props, State> {

	updateValue = (event: ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const value = event.target.value.trimLeft();
		this.props.onChange(name, value);
	};

	render() {
		const {t, required, name, value, rows, validation, textFieldProps} = this.props;

		const hasError = (!!(validation && validation.error));
		const error = (validation && validation.error ? t(validation.error!) : " ");

		const id = (this.props.id ? this.props.id : name);
		const label = (this.props.label ? this.props.label : name);
		const autoComplete = this.props.autoComplete ? this.props.autoComplete : "off";

		const numberOfRows = (rows && rows > 0 ? rows : 4);

		return (
			<TextField
				{...textFieldProps}
				fullWidth
				multiline={true}
				rows={numberOfRows}
				required={required}
				autoComplete={autoComplete}
				id={id}
				name={name}
				variant="filled"
				label={t(label)}
				value={value}
				onChange={this.updateValue}
				error={hasError}
				helperText={error}
				InputProps={{sx: {color: hasError ? "#EB5757" : "#484848", fontSize: "20px", lineHeight: "30px", fontFamily: "TTNorms-Pro-Bold", fontWeight: 400}}}
			/>
		);
	}
}

export default withTranslation('proactive')(FormTextArea);
