export const GENDER_OPTIONS = ["female", "male"];
export const VALID_LANGUAGES = ["en", "de", "fr"];
export const DEFAULT_LANGUAGE = "de";

export const MAX_FILE_SIZE = 5 * 1024 * 1024;
export const ACCEPTED_FILE_EXTENSIONS = ["pdf", "doc", "docx"];

// Should be change in future https://developer.mozilla.org/en-US/docs/Web/API/window/showOpenFilePicker#parameters
export const ACCEPTED_MIME_TYPES = [
	"application/pdf",
	"application/msword",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
];
export const MAX_EXPERIENCES = 3;
