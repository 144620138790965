import {Container} from "@mui/material";
import Grid from '@mui/material/Grid';
import * as React from 'react';
import {withTranslation} from 'react-i18next';
import {DefaultProps} from '../../pages/App/App';
import VisibilityContainer from '../VisibilityContainer';
import './KPI.scss';
import KPIInfo from './KPIInfo';
import KPIMeasurments from './KPIMeasurments';

interface Props extends DefaultProps {
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
	type: "info" | "measurment" | undefined;
	backgroundImage: any;
}

interface State {
}

class KPI extends React.Component<Props, State> {
	render() {
		const {type, children, backgroundImage} = this.props;

		let maxWidth = this.props.maxWidth;
		if (maxWidth === undefined) {
			maxWidth = "lg";
		}

		return (
			<Container disableGutters maxWidth={maxWidth}>
				<Grid container spacing={0} direction="row" justifyContent="flex-start" alignItems="center"
							sx={{padding: {xl: "0", lg: "0 80px", md: "0 60px", sm: "0 30px", xs: "0 15px"}}}>
					<Grid item xs={12} justifyContent="center" alignItems="center">
						<Grid container item className="kpi" sx={{
							padding:             {lg: "80px", md: "60px", sm: "30px", xs: "30px 15px"},
							backgroundImage:     `url(${backgroundImage})`,
							backgroundColor:     'rgba(0, 0, 0, 0.3)',
							backgroundBlendMode: 'darken'
						}}>
							<VisibilityContainer maxWidth={false} transition="slide" slideDirection="up">
								{children}
							</VisibilityContainer>
						</Grid>
						{type === "info" ? <KPIInfo /> : null}
						{type === "measurment" ? <KPIMeasurments /> : null}
					</Grid>
				</Grid>
			</Container>
		);
	}
}

export default withTranslation("proactive")(KPI);
