const typography = {
  fontFamily: ["TTNorms-Pro-Regular", "TTNorms-Pro-Bold", "WayFinder"].join(","),
  fontSize: 14,

  h1: {
    fontFamily: "WayFinder",
    fontWeight: 400,
    letterSpacing: "-0.03em",
  },
  h2: {
    fontFamily: "WayFinder",
    fontWeight: 400,
    letterSpacing: "-0.03em",
  },
  h3: {
    fontFamily: "WayFinder",
    fontWeight: 400,
    letterSpacing: "-0.03em",
  },
  h4: {
    fontFamily: "WayFinder",
    fontWeight: 400,
    letterSpacing: "-0.03em",
  },
  h5: {
    fontFamily: "WayFinder",
    fontWeight: 400,
    letterSpacing: "-0.03em",
  },
  subtitle1:
  {
    // p bold
    fontFamily: "TTNorms-Pro-Bold",
    fontWeight: 400,
  },
  body1:
  {
    // p regular
    fontFamily: "TTNorms-Pro-Regular",
    fontWeight: 400,
  }
};

export default typography;
