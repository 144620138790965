import Logger from "js-logger";

if (process.env.NODE_ENV !== "production") {
  // ESLint thinks this is a react hook, it's not
  // eslint-disable-next-line
  Logger.useDefaults();

  if (process.env.NODE_ENV === "development") {
    Logger.setLevel(Logger.DEBUG);
  } else {
    Logger.setLevel(Logger.INFO);
  }
}

export default Logger;
