import {Button, Container} from "@mui/material";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {SxProps} from "@mui/system";
import * as React from 'react';
import {withTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import {DefaultProps} from '../../pages/App/App';
import VisibilityContainer from '../VisibilityContainer';

interface Props extends DefaultProps {
	title?: string;
	subtitle?: string;
	button?: string;
	buttonPath?: string;
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
	slideDirection?: "up" | "left" | "right";
	backgroundImage?: any;
}

interface State {
}

class TextSection extends React.Component<Props, State> {
	render() {
		const {title, subtitle, button, buttonPath} = this.props;

		let maxWidth = this.props.maxWidth;
		if (maxWidth === undefined) {
			maxWidth = "lg";
		}

		let slideDirection = this.props.slideDirection;
		if (slideDirection === undefined) {
			slideDirection = "left";
		}

		const sxProps = this.props.backgroundImage ? {
			backgroundImage:     `url(${this.props.backgroundImage})`,
			backgroundPosition:  "center !important",
			borderBottom:        "1px solid #F5F5F5",
			backgroundColor:     "rgba(0, 0, 0, 0.2)",
			backgroundBlendMode: "darken",
			padding:             {xl: "60px 0", lg: "30px 0", md: "30px 0", sm: "60px 0", xs: "60px 0"}
		} as SxProps : null;

		const titleColor = this.props.backgroundImage ? "#66FFA3" : "";
		const textColor = this.props.backgroundImage ? "#FFFFFF" : "";

		return (
			<Container disableGutters maxWidth={false}>
				<Grid container spacing={0} direction="row" justifyContent="flex-start" alignItems="center" sx={sxProps}>
					<VisibilityContainer maxWidth={maxWidth} transition="slide" slideDirection={slideDirection}>
						<Grid container item xs={12} sx={{padding: {xl: "80px 80px", lg: "80px 160px", md: "60px 120px", sm: "30px 60px", xs: "30px 30px"}}}>
							{title !== undefined && (
								<Grid item xs={12}>
									<Typography variant="h2" component="h2" sx={{
										color:      titleColor ? titleColor : null,
										fontSize:   {lg: "80px", md: "70px", xs: "50px"},
										lineHeight: {lg: "75px", md: "65px", xs: "45px"},
									}} dangerouslySetInnerHTML={{__html: title}} />
								</Grid>
							)}
							{subtitle !== undefined && (
								<Grid item xs={12}>
									<Typography variant="body1" component="div" sx={{
										color:      textColor ? textColor : null,
										fontSize:   {md: "20px", xs: "18px"},
										lineHeight: {md: "30px", xs: "26px"},
										paddingTop: "25px",
									}} dangerouslySetInnerHTML={{__html: subtitle}}></Typography>
								</Grid>
							)}
							{button !== undefined && (
								<Grid item xs={12} sx={{paddingTop: "25px"}}>
									<Button type="button" className="whiteButton" component={Link} to={buttonPath!}>
										<Typography variant="subtitle1" component="p" sx={{
											fontSize:   {lg: "16px", md: "16px", xs: "16px"},
											lineHeight: {lg: "22px", md: "22px", xs: "22px"},
										}}>
											{button}
										</Typography>
									</Button>
								</Grid>
							)}
						</Grid>
					</VisibilityContainer>
				</Grid>
			</Container>
		);
	}
}

export default withTranslation("proactive")(TextSection);
