import {DEFAULT_LANGUAGE, VALID_LANGUAGES} from "./Constants";


export function GetLanguageFromBrowser() {
	let browserLang = window.navigator.language.slice(0, 2);
	if (VALID_LANGUAGES.indexOf(browserLang) !== -1) {
		return browserLang;
	}
	return DEFAULT_LANGUAGE;
}
