import {MenuItem} from "@mui/material";
import TextField, {TextFieldProps} from '@mui/material/TextField';
import * as React from 'react';
import {ChangeEvent} from 'react';
import {withTranslation} from 'react-i18next';
import {FieldValidation} from '../../helpers/ValidationHelpers';
import {DefaultProps} from '../../pages/App/App';

interface Props extends DefaultProps {
	id?: string;
	name: string;
	label?: string;
	required: boolean;
	options: string[];
	value: string;
	onChange: any;
	validation?: FieldValidation;
	textFieldProps?: TextFieldProps;
}

interface State {
}

class FormSelectField extends React.Component<Props, State> {
	updateValue = (event: ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const value = event.target.value.trimStart();
		this.props.onChange(name, value);
	};

	render() {
		const {t, required, name, value, options, validation, textFieldProps} = this.props;

		const hasError = !!(validation && validation.error);
		const error = validation && validation.error ? t(validation.error!) : " ";

		const id = this.props.id ? this.props.id : name;
		const label = this.props.label ? this.props.label : name;

		return (
			<TextField
				{...textFieldProps}
				select
				fullWidth
				required={required}
				id={id}
				name={name}
				variant="filled"
				label={t(label)}
				value={value}
				onChange={this.updateValue}
				error={hasError}
				helperText={error}
				InputProps={{sx: {color: hasError ? "#EB5757" : "#484848", fontSize: "20px", lineHeight: "30px", fontFamily: "TTNorms-Pro-Bold", fontWeight: 400}}}
				InputLabelProps={{sx: {color: hasError ? "#EB5757" : "#484848"}}}
			>
				{options.map((option) => (
					<MenuItem key={option} value={option}>
						{t(option)}
					</MenuItem>
				))}
			</TextField>
		);
	}
}

export default withTranslation("proactive")(FormSelectField);
