export enum APIErrorCode {
	NoConnection = 1,
	ServerError = 1000,
	ThrottlingError = 1002,
	JobOfferNotFound = 2000,
	InvalidRequestBody = 1101,
	JobApplicationNotFound = 2100,
	JobApplicationAlreadyWithdrawn = 2102,
	JobApplicationAlreadyHandled = 2103,
}

export default class APIError extends Error {
	readonly code: APIErrorCode;

	constructor(code: APIErrorCode, httpStatus: number) {
		super();

		if (code === undefined) {
			if (httpStatus === 429) {
				this.code = APIErrorCode.ThrottlingError
			} else {
				this.code = APIErrorCode.ServerError
			}
		} else {
			this.code = code;
		}
	}
}
