import Container from '@mui/material/Container';
import {Theme} from "@mui/material/styles";
import {SxProps} from "@mui/system";
import * as React from 'react';
import {HTMLAttributes, RefAttributes} from 'react';
import {withTranslation} from 'react-i18next';
import {DefaultProps} from '../pages/App/App';

// const defaultTransitionTimeout = 700;

interface Props extends DefaultProps {
	subtitle?: string;
	htmlAttr?: HTMLAttributes<any>;
	refAttr?: RefAttributes<any>;
	className?: string;
	noPadding?: boolean;
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
	transition?: "fade" | "slide";
	slideDirection?: "up" | "left" | "right";
	sx?: SxProps<Theme>;
}

interface State {
	isVisible: boolean;
}

class VisibilityContainer extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			isVisible: false,
		};
	}

	// private visibilityChanged = (isVisible: boolean) => {
	// 	if (isVisible) {
	// 		this.setState({
	// 			isVisible: true,
	// 		});
	// 	}
	// };

	render() {
		const {children, sx} = this.props;
		// const isVisible = this.state.isVisible;

		let maxWidth = this.props.maxWidth;
		if (maxWidth === undefined) {
			maxWidth = "lg";
		}

		/*let transition = <Fade in={isVisible} timeout={defaultTransitionTimeout}>
			<div>{children}</div>
		</Fade>

		if (this.props.transition === 'slide') {
			transition = <Slide direction="up" in={isVisible} timeout={defaultTransitionTimeout}>
				<div>{children}</div>
			</Slide>
		}*/

		return (
			// <VisibilitySensor partialVisibility={true} intervalCheck={true} scrollCheck={true} resizeCheck={true} onChange={this.visibilityChanged}>
			<Container disableGutters maxWidth={maxWidth} sx={sx}>
				<div>{children}</div>
			</Container>
			// </VisibilitySensor>
		);
	}
}

export default withTranslation("proactive")(VisibilityContainer);