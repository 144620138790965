import Container from '@mui/material/Container';
import * as React from 'react';

interface Props  {
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  size?: "small" | "default" | "large";
}

interface State {}

class Divider extends React.Component<Props, State> {
  render() {
    const { children } = this.props;

    let maxWidth = this.props.maxWidth;
    if (maxWidth === undefined) {
      maxWidth = "lg";
    }

    let minHeight = { xl: "130px", lg: "130px", md: "110px", sm:"110px", xs:"110px" };
    if (this.props.size === "large") {
      minHeight = { xl: "230px", lg: "230px", md: "210px", sm:"180px", xs:"150px" };
    } else if (this.props.size === "small") {
      minHeight = { xl: "70px", lg: "70px", md: "50px", sm:"50px", xs:"50px" };
    }

    return (
      <Container
        disableGutters
        maxWidth={maxWidth}
        sx={{
          display: "block",
          minHeight: minHeight,
        }}
      >
        {children}
      </Container>
    );
  }
}

export default Divider;
