import {CircularProgress, Grid} from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Logger from 'js-logger';
import * as React from 'react';
import {HTMLAttributes, RefAttributes} from 'react';
import {Trans, withTranslation} from 'react-i18next';
import APIClient from '../../helpers/APIClient';
import APIError from '../../helpers/APIError';
import {GENDER_OPTIONS} from '../../helpers/Constants';
import {GetLanguageFromBrowser} from '../../helpers/LanguageHelpers';
import {FieldValidation, isEmpty, isValidEmail, isValidGender, isValidName, isValidPhone} from '../../helpers/ValidationHelpers';
import {ContactData} from '../../models/ContactData';
import SuccessResponse from '../../models/SuccessResponse';
import {DefaultProps} from '../../pages/App/App';
import ConfirmationView from '../ConfirmationView/ConfirmationView';
import ErrorDialog from '../ErrorDialog/ErrorDialog';
import FormSelectField from '../FormSelectField/FormSelectField';
import FormTextArea from '../FormTextArea/FormTextArea';
import FormTextField from '../FormTextField/FormTextField';
import VisibilityContainer from '../VisibilityContainer';
import './ContactForm.scss';

interface Props extends DefaultProps {
	onSuccess?: () => void;
	htmlAttr?: HTMLAttributes<any>;
	refAttr?: RefAttributes<any>;
	maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
	color?: string;
	title: string;
	subtitle: string;
	submitButtonText: string;
}

interface State {
	data: ContactData;
	validation: FormValidation;
	formSubmitted: boolean;
	errorCode?: number;
	isLoading: boolean;
}

interface FormValidation {
	filledFields: string[];
	gender?: FieldValidation;
	firstname?: FieldValidation;
	lastname?: FieldValidation;
	email?: FieldValidation;
	phone?: FieldValidation;
	company?: FieldValidation;
	message?: FieldValidation;
	isValid: boolean;

	[key: string]: any;
}

class ContactForm extends React.Component<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
			data:          {
				gender:    "",
				firstname: "",
				lastname:  "",
				company:   "",
				email:     "",
				phone:     "",
				message:   "",
				language:  GetLanguageFromBrowser(),
			},
			validation:    {
				filledFields: [],
				isValid:      false,
			},
			formSubmitted: false,
			isLoading:     false,
		};
	}

	private updateData = (field: string, value: string) => {
		Logger.debug(`Updating ${field}: ${value}`);

		const data = this.state.data;
		let validation = this.state.validation;

		data[field] = value;

		if (validation.filledFields.indexOf(field) < 0) validation.filledFields.push(field);

		validation = this.validateForm(data, validation);

		this.setState({
			data,
			validation,
		});

		Logger.debug(data);
	};

	private validateForm = (data: ContactData, validation: FormValidation, onlyFilledFields = true) => {
		let isValid = true;

		["gender", "firstname", "lastname", "email", "company", "message"].forEach((field) => {
			if (!onlyFilledFields || validation.filledFields.indexOf(field) !== -1) {
				let v = ContactForm.validateField(field, data[field]);
				if (v.error != null) isValid = false;
				validation[field] = v;
			}
		});

		["phone"].forEach((field) => {
			if (!onlyFilledFields || validation.filledFields.indexOf(field) !== -1) {
				let v = ContactForm.validateField(field, data[field], true);
				if (v.error != null) isValid = false;
				validation[field] = v;
			}
		});

		validation.isValid = isValid;
		return validation;
	};

	private static validateField(field: string, value: string, allowEmpty: boolean = false) {
		let validation: FieldValidation = {
			field: field,
		};

		if (!allowEmpty && isEmpty(value)) validation.error = "errors.validation.empty";

		if (field === "gender") {
			if (!isValidGender(value)) validation.error = "errors.validation.gender";
		}

		if (field === "firstname" || field === "lastname") {
			if (!isValidName(value)) validation.error = "errors.validation.name";
		}

		if (field === "email") {
			if (!isValidEmail(value)) validation.error = "errors.validation.email";
		}

		if (field === "phone") {
			if (!isValidPhone(value)) validation.error = "errors.validation.phone";
		}

		return validation;
	}

	private submitForm = () => {
		const data = this.state.data;
		const isLoading = this.state.isLoading;

		if (isLoading) {
			return;
		}

		let validation = this.state.validation;
		validation = this.validateForm(data, validation, false);

		if (!validation.isValid) {
			Logger.debug(`Invalid form data: ${JSON.stringify(validation)}`);
			this.setState({validation});
			return;
		}

		this.setState({
			isLoading: true,
		});

		Logger.debug(`Submitting contact form...`);
		APIClient.sendB2BSignup(data)
			.then((response: SuccessResponse) => {
				Logger.info(`Successfully sent form data: ${response.message}`);

				this.setState({
					formSubmitted: true,
					isLoading:     false,
				});

				if (this.props.onSuccess !== undefined) {
					this.setState({
						formSubmitted: true,
					});
					this.props.onSuccess();
				}
			})
			.catch((error: APIError) => {
				Logger.error(`Failed to submit form: ${JSON.stringify(error)}`);
				this.setState({
					errorCode: error.code,
					isLoading: false,
				});
			});
	};

	private dismissErrorDialog = () => {
		this.setState({
			errorCode: undefined,
		});
	};

	render() {
		const {t, htmlAttr, refAttr, title, subtitle, submitButtonText} = this.props;
		const {data, validation, formSubmitted, errorCode, isLoading} = this.state;

		let maxWidth = this.props.maxWidth;
		if (maxWidth === undefined) {
			maxWidth = "lg";
		}
		const hasError = errorCode! > 0;

		return (
			<VisibilityContainer maxWidth={maxWidth} transition="slide" slideDirection="up">
				<form {...htmlAttr} {...refAttr} className="contact-form" noValidate autoComplete="off">
					<Grid container sx={{padding: {xl: "0", lg: "0 80px", md: "0 60px", sm: "0 30px", xs: "0 15px"}}}>
						<Grid
							item
							xs={12}
							sx={{
								padding: {
									lg: "0 80px 40px",
									md: "0 60px 30px",
									sm: "0 30px 20px",
									xs: "0 15px 10px",
								},
							}}
						>
							{title !== undefined && (
								<Typography
									variant="h2"
									component="h2"
									sx={{
										fontSize:      {lg: "80px", md: "70px", xs: "50px"},
										lineHeight:    {lg: "75px", md: "65px", xs: "45px"},
										paddingBottom: "15px",
									}}
									dangerouslySetInnerHTML={{__html: title}}
								/>
							)}
							{subtitle !== undefined && (
								<Typography variant="body1" sx={{fontSize: "20px", lineHeight: "30px", padding: {xs: "0 0 30px 0"}}}>
									{subtitle}
								</Typography>
							)}
						</Grid>
						<Grid
							container
							item
							xs={12}
							sx={{
								backgroundColor: this.props.color,
								borderRadius:    "15px",
								padding:         {lg: "80px 80px", md: "40px 60px", sm: "30px", xs: "30px 15px"},
							}}
						>
							{formSubmitted && <ConfirmationView headline={t("contact_form.confirmation_title")} description={t("contact_form.confirmation_description")} />}
							{!formSubmitted && <Grid container alignContent="space-between" alignItems="center" direction="row">
				  <Grid item xs={12} className="contact-form-field">
					  <FormSelectField
						  required={true}
						  name="gender"
						  value={data.gender}
						  onChange={this.updateData}
						  options={GENDER_OPTIONS}
						  validation={validation.gender}
						  textFieldProps={{color: "primary"}}
					  />
				  </Grid>
				  <Grid item xs={12} className="contact-form-field">
					  <FormTextField
						  required={true}
						  name="firstname"
						  label="contact_form.firstname"
						  value={data.firstname}
						  onChange={this.updateData}
						  autoComplete="given-name"
						  validation={validation.firstname}
						  textFieldProps={{color: "primary"}}
					  />
				  </Grid>
				  <Grid item xs={12} className="contact-form-field ">
					  <FormTextField
						  required={true}
						  name="lastname"
						  label="contact_form.lastname"
						  value={data.lastname}
						  onChange={this.updateData}
						  autoComplete="family-name"
						  validation={validation.lastname}
						  textFieldProps={{color: "primary"}}
					  />
				  </Grid>
				  <Grid item xs={12} className="contact-form-field">
					  <FormTextField
						  required={true}
						  name="email"
						  label="contact_form.email"
						  value={data.email}
						  onChange={this.updateData}
						  autoComplete="email"
						  validation={validation.email}
						  textFieldProps={{color: "primary"}}
					  />
				  </Grid>
				  <Grid item xs={12} className="contact-form-field">
					  <FormTextField
						  required={false}
						  name="phone"
						  label="contact_form.phone"
						  value={data.phone}
						  onChange={this.updateData}
						  validation={validation.phone}
						  textFieldProps={{
														color:      "primary",
														inputProps: {type: "tel"},
													}}
					  />
				  </Grid>
				  <Grid item xs={12} className="contact-form-field">
					  <FormTextField
						  required={true}
						  name="company"
						  label="contact_form.company"
						  value={data.company}
						  onChange={this.updateData}
						  validation={validation.company}
						  textFieldProps={{color: "primary"}}
					  />
				  </Grid>
				  <Grid item xs={12} className="contact-form-field">
					  <FormTextArea
						  required={true}
						  name="message"
						  label="contact_form.message"
						  value={data.message}
						  onChange={this.updateData}
						  rows={6}
						  validation={validation.message}
						  textFieldProps={{color: "primary"}}
					  />
				  </Grid>
				  <Grid container item xs={12} className="contact-form-field">
					  <p><Trans i18nKey="contact_form.tos_privacy_notice">
						  Unsere Datenschutzerklärung findest du <a href="https://www.jobcloud.ch/c/de-ch/datenschutzerklarung/" target="_blank"
																	rel="noopener noreferrer">hier</a>.
					  </Trans></p>
				  </Grid>
				  <Grid item xs={12}>
					  <Button variant="contained" size="large" color="primary" onClick={this.submitForm} disabled={isLoading} className="submitButton"
							  sx={{minWidth: {sm: "auto", xs: "100%"}, margin: "30px 0 0 0"}}>
												{isLoading && <CircularProgress size={24} color="secondary" />}
												{submitButtonText !== undefined ? submitButtonText : t("contact_form.submit")}
					  </Button>
				  </Grid>
			  </Grid>}
							<ErrorDialog code={errorCode ? errorCode : 0} show={hasError} onDismiss={this.dismissErrorDialog} />
						</Grid>
					</Grid>
				</form>
			</VisibilityContainer>
		);
	}
}

export default withTranslation("proactive")(ContactForm);
