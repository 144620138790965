/* eslint-disable import/no-unresolved, import/no-webpack-loader-syntax */
import { createTheme } from "@mui/material/styles";
import typography from "./typography";

const MuiTheme = createTheme({
  palette: {
    primary: {
      main: "#4B4B4B",
      contrastText: "#4B4B4B",
    },
    secondary: {
      main: "#EEFF84",
      contrastText: "#4B4B4B",
    },
    text: {
      primary: "#4B4B4B",
      secondary: "#FFFFFF",
    },
    background: {
      paper: "#FFFFFF",
      default: "#F5F5F5",
    },
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  shadows: [
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
    "none",
  ],
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1536,
    },
  },
  components: {
    MuiButtonBase: {
      "color": "#4B4B4B",
      "&:hover": {
        color: "#4B4B4B",
      },
    },
    MuiButton: {
      sizeSmall: {
        fontSize: 15,
      },
      sizeLarge: {
        fontSize: 20,
      },
      containedPrimary: {
        border: "1px solid white",
      },
      root: {
        "backgroundColor": "transparent",
        "color": "#4B4B4B",
        "textTransform": "none",
        "fontSize": 16,
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "zIndex": "30",
          "&.Mui-focused": {
            "color": "#484848",
            "&:hover": {
              color: "#484848",
              borderColor: "transparent",
            },
          },
          "fontSize": "16px",
          "color": "#484848",
          "&:hover": {
            color: "#484848",
            borderColor: "transparent",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "fontSize": "12px",
          "lineHeight": "12px",
          "&.Mui-error": {
            fontSize: "20px",
            lineHeight: "30px",
            fontFamily: "TTNorms-Pro-Bold",
            padding: "30px 30px 20px 30px",
            backgroundColor: "#EB5757",
            color: "#FFFFFF",
            borderRadius: "0 0 10px 10px",
            margin: "-10px 0 10px 0",
            zIndex: "10",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
    MuiOutlinedInput: {
      "&.Mui-focused": {
        "borderColor": "green !important",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "blue !important",
        },
      },
      "styleOverrides": {
        root: {
          zIndex: "20",
          color: "#484848",
          borderColor: "transparent",
          padding: 0,

          notchedOutline: {
            "&.Mui-focused": {
              borderColor: "green !important",
            },
            "&:hover": {
              color: "#222222",
            },
          },
        },
      },
    },
  },
  typography,
});

export default MuiTheme;
