
class GlobalsEnvLoader {

	private readonly prefix: string;
	private readonly vars: Map<string,string>;

	constructor(prefix: string) {
		this.prefix = prefix;
		this.vars = new Map<string,string>();
		this.loadFromEnv();
	}

	loadFromEnv() {
		let env = process.env;
		for (let key in env) {
			if (env.hasOwnProperty(key)) {
				if (key.startsWith(this.prefix) && env[key] !== undefined) {
					this.vars.set(key.substring(this.prefix.length), env[key]!)
				}
			}
		}
	}

	getVars(): Map<string,string> {
		return this.vars;
	}
}

const Globals = new GlobalsEnvLoader("REACT_APP_").getVars();
export default Globals;
