const translations = {
	de: {
		proactive: {
			retry:         "Nochmals versuchen",
			dismiss:       "Okay",
			gender:        "Anrede",
			male:          "Herr",
			female:        "Frau",
			errors:        {
				generic:    'Fehler',
				code:       {
					0:    "Unbekannter Fehler, bitte versuch es später nochmals.",
					1:    "Server nicht erreichbar, bist du offline?",
					1000: "Es ist ein Fehler aufgetreten, bitte versuch es später nochmals.",
					1001: "Es ist ein Fehler aufgetreten, bitte versuch es später nochmals.",
					1002: "Wir erhalten momentan zu viele Anfragen, bitte versuche es später nochmals.",
					1100: "Bitte überprüfe deine Daten und versuche es nochmals.",
					1101: "Bitte überprüfe deine Daten und versuche es nochmals.",
					1102: "Bitte überprüfe deine Daten und versuche es nochmals.",
					1103: "Bitte überprüfe deine Daten und versuche es nochmals.",
					2000: "Kein Angebot gefunden, du bist möglicherweise einem alten Link gefolgt.",
					2100: "Wir konnten Ihre Bewerbung nicht finden.",
					2101: "Du hast uns dein Lebenslauf bereits gesendet. Bitte warte bis wir mit dir Kontakt aufgenommen haben.",
					2102: "Die Bewerbung wurde bereits zurückgezogen.",
					2103: "Die Bewerbung wurde bereits bearbeitet, bitte kontaktieren Sie uns für weitere Informationen.",
					3000: "Es kann nur eine Datei ausgewählt werden.",
					3001: "Die Datei ist zu gross.",
					3002: "Der Dateityp ist nicht gültig. Es werden nur Dateien im PDF und Word-Format unterstützt.",
					3003: "Es gab ein Fehler beim Lesen der Datei. Bitte versuch es nochmals.",
				},
				validation: {
					empty:            "Kann nicht leer sein",
					email:            "Ungültige E-Mail Adresse",
					name:             "Ungültiger Name",
					cv_document:      "Kein Lebenslauf ausgewählt",
					phone:            "Ungültige Telefonnummer",
					gender:           "Ungültige Anrede",
					tos:              "Muss akzeptiert werden um fortzufahren.",
					max_skills_limit: "Es können max. {{max_skills}} Skills pro Erfahrung angegeben werden",
				}
			},
			cookieconsent: {
				text:   "Diese Webseite benutzt Cookies, um Ihnen den bestmöglichen Service zu bieten. Weitere Informationen sind in unseren <1>AGB</1> und in der <3>Datenschutzerklärung</3> zu finden.",
				button: {
					accept: "Akzeptieren",
					reject: "Ablehnen",
				},
			},
			footer:        {
				tos:  "AGB",
				pp:   "Datenschutzerklärung",
				made: "<0>Made with </0><1></1><2> by JobCloud</2>",
			},
			breadcrumbs:   {
				home:       "Home",
				recruiting: "Recruiting as a Service",
				sourcing:   "Sourcing",
				databank:   "CV-Datenbank",
				team:       "Über Spotted",
			},
			navigation:    {
				home:           "Home",
				clients:        "Kandidaten",
				team:           "Team",
				contact_button: "Angebot einholen",
				apply_button:   "Jetzt Lebenslauf hochladen",
				team_button:    "Jetzt kontaktieren",
				companies:      "Arbeitgeber",
				mobile:         {
					companies: "Arbeitgeber",
					talents:   "Kandidaten",
					team:      "Team",
				},
			},
			apply_form:    {
				firstname:                "Vorname",
				lastname:                 "Name",
				email:                    "E-Mail",
				phone:                    "Telefonnummer (+41 12 345 67 89)",
				selected_file:            "Ausgewählte Datei",
				professionalTitle:        "Beruf",
				experience:               "Erfahrung",
				experience_jobtitle:      "Funktion",
				experience_duration:      "Dauer",
				experience_location:      "Arbeitsort",
				experience_proficiency:   "Erfahrungsstufe",
				experience_skills:        "Skills",
				years:                    "Jahre",
				months:                   "Monate",
				intern:                   "Intern",
				junior:                   "Junior",
				professional:             "Professional",
				senior:                   "Senior",
				management:               "Management",
				executive:                "Executive",
				add_experience:           "Erfahrung hinzufügen...",
				cvDocument:               "Lebenslauf hierher ziehen, oder klicken um zu durchsuchen...",
				tos_privacy_notice:       "Mit dem Absenden dieses Formulars erklärst du dich mit den <1>Nutzungsbedingungen für Spotted (Digital Recruiting by JobCloud)</1> einverstanden. Unsere Datenschutzerklärung findest du <3>hier</3>.",
				submit:                   "Lebenslauf senden",
				confirmation_title:       "Lebenslauf erfolgreich gesendet",
				confirmation_description: "Vielen Dank! Du wirst von uns kontaktiert, sobald wir eine spannende Position für dich identifiziert haben.",
			},
			contact_form:  {
				firstname:                "Vorname",
				lastname:                 "Name",
				email:                    "E-Mail",
				phone:                    "Telefonnummer (+41 12 345 67 89)",
				company:                  "Firma",
				message:                  "Wie können wir helfen?",
				tos_privacy_notice:       "Unsere Datenschutzerklärung findest du <1>hier</1>.",
				submit:                   "Formular absenden",
				confirmation_title:       "Daten erfolgreich gesendet",
				confirmation_description: "Vielen Dank! Unser Team wird sich in den kommenden Tagen bei Ihnen melden.",
			},
			spotted:       {
				spotted_by_jobs: "Spotted by jobs.ch",
				jobcloud:        "JobCloud",
				talents:         {
					section1: {
						title:  "Dein Talent wird jetzt gebraucht.",
						body:   "Bei Spotted zählt der Mensch! Deine Fähigkeiten, deine Erfahrungen und deine Persönlichkeit. Wir verbinden dich mit Unternehmen und Chancen, die zu dir passen.",
						button: "Jetzt Lebenslauf hochladen",
					},
					section2: {
						title: "Passende Jobangebote mit wenig Aufwand",
						body:  "Unsere Recruiter, unterstützt durch unsere AI-Technologie, vergleichen deinen Lebenslauf mit ausgewählten Jobangeboten (exklusiv oder öffentlich). Aber keine Angst, wir belästigen dich nicht mit unzähligen Nachrichten, Treffer werden von unseren Recruiting-Spezialisten sorgfältig geprüft. Unser Team kontaktiert dich nur dann, wenn wir ein wirklich gutes Angebot für dich haben.",
					},
					section3: {
						title: "So einfach geht’s",
						body:  "Keine komplizierte Jobsuche und online Bewerbungsformulare. Einfach deinen Lebenslauf hochladen und wir können dich finden. Identifizieren wir einen Treffer, stellen wir den Kontakt zwischen dir und dem Arbeitgeber her. Das Ganze ist für dich völlig kostenlos.",
						steps: {
							title1: "Lebenslauf hochladen",
							body1:  "Wir nutzen künstliche Intelligenz gepaart mit der Expertise und dem Engagement unserer Recruiting-Spezialisten, um deinen Lebenslauf mit ausgewählten Jobangeboten abzugleichen.",
							title2: "Passgenaue Jobs erhalten",
							body2:  "Sobald ein Job für dich von Interesse sein könnte, wirst du von uns persönlich kontaktiert und erhältst Informationen zur Firma und Vakanz. Nun kannst du entscheiden, ob du durch uns vorgestellt werden möchtest.",
							title3: "Gegenseitig Kennenlernen",
							body3:  "Wir rücken dich beim Arbeitgeber ins Spotlight. Überzeugst du auch dort, koordinieren wir die nächsten Schritte. Jetzt bist du dran, lerne das Unternehmen kennen und hinterlasse einen positiven Eindruck.",
						},
					},
					contact:  {
						title:  "Jetzt Lebenslauf hochladen",
						body:   "Lade deinen Lebenslauf jetzt als PDF- oder Word-Datei hoch, damit du innerhalb der nächsten sechs Monate von uns gefunden werden kannst. Wenn wir eine passende Position für dich identifizieren, melden wir uns bei dir.",
						button: "Lebenslauf senden",
					},
				},
				companies:       {
					section1:    {
						title:  "Gemeinsam sourcen!<br/>Wir matchen, du wählst.",
						items:  [
							"Schnelles Sourcing und kostengünstige Rekrutierung",
							"Neueste Matching-Technologie kombiniert mit menschlichem Engagement",
							"Active Sourcing für direkte Talentansprache, Active Sourcing PRO für umfassende, individuelle Rekrutierungslösungen",
						],
						button: "Jetzt kontaktieren",
					},
					section2:    {
						title: "Fast sourcing, successful recruitment, happy talent, happy you!",
						body:  "Richtig gelesen – mit uns erlebst du eine sorglose Rekrutierung. Warum? Wir wissen, wie anspruchsvoll die Suche nach dem perfekten Talent sein kann. Spotted geht deshalb neue Wege der Talentsuche und bietet dir zwei massgeschneiderte Lösungen. Mit uns rekrutierst du effizient und performancebasiert, ohne Mühe und ohne Qual, mit der optimalen Mischung aus persönlichen Rekrutierungsexperten und künstlicher Intelligenz. Klingt gut?",
					},
					section3:    {
						title:  "Wir sind deine Matchmaker",
						body:   "<p>Spotted unterstützt Unternehmen, schneller und effizienter zu rekrutieren – mit Experten-Know-how und Sourcing-Services.</p><p>Wir revolutionieren das Recruiting – im April 2020 von JobCloud lanciert, verfügen wir über mehr als 20 Jahre Erfahrung im Schweizer Stellenmarkt.</p><p>Mit der optimalen Mischung aus engagierten Recruitern, erfahrenen Campaign Managern und neuesten Matching-Technologien maximieren wir deinen Rekrutierungserfolg.</p>",
						action: "Jetzt kennenlernen"
					},
					services:    {
						title:      "Unsere Services",
						otherTitle: "Unsere weiteren Services",
						body:       "Entdecke unser Sourcing-Angebot. Wir sprechen sowohl passive als auch aktive Talente direkt an. Wähle aus unseren Services.",
						type:       {
							title1: "Active Sourcing",
							body1:  "Finde in kurzer Zeit passende, qualifizierte und interessierte Kandidatinnen und Kandidaten: Ideal für die Branchen Retail und Gastronomie, aber auch für Positionen wie beispielsweise Sachbearbeiter, Versicherungsberater, Customer Service oder Payroll Specialist. ",
							title2: "Active Sourcing PRO",
							body2:  "Wir unterstützen dich umfassend und persönlich bei komplexen, anspruchsvollen Anforderungen: Ideal für die Branchen Industrie und IT, insbesondere für Positionen wie Servicetechniker, Polymechaniker, ICT System Engineer oder ICT Software Engineer (weitere Rollen gerne möglich nach Absprache)."
						},
					},
					clients:     {
						title: "Unsere Kunden",
					},
					testmonials: [
						{
							quote:   "“Spotted schafft es die Kandidaten rascher zu identifizieren und aktivieren, ohne dass dabei die Qualität leidet.”",
							name:    "Monika Steinemann",
							company: "Brütsch Rüegger Werkzeuge AG",
						},
						{
							quote:   "“Spotted agiert dynamisch, transparent und kompetent. Unseren ersten Suchauftrag konnten wir innerhalb von 2 Wochen mit einer Rekrutierung abschliessen.”",
							name:    "Christoph Siegenthaler",
							company: "EAO AG",
						},
						{
							quote:   "“Dank der persönlichen Beratung und Expertise von Spotted, bekommen wir auch bei sehr schwierigen Profilen passende Kandidaten in unseren Prozess.”",
							name:    "Kathrin Hofmann",
							company: "Galenica",
						}
					],
					kpi:         {
						title:      "Der einfachste Weg zu deinen neuen Mitarbeitenden",
						body:       "Aufgrund des Fachkräftemangels sind klassische Rekrutierungswege nur noch selten zielführend. Die besten Talente sind heute nicht mehr aktiv auf Stellensuche. Wir verfügen über starke Kanäle – und finden Talente dort, wo sie sich verbergen.",
						percentage: {
							number1: "35",
							body1:   "der passenden Stellenangebote werden von Talenten übersehen",
							number2: "50",
							body2:   "der Talente bevorzugen eine persönliche Ansprache durch Recruiter",
							number3: "60",
							body3:   "der ausgeschriebenen Stellen können nicht nur mit Stelleninseraten besetzt werden",
							number4: "80",
							body4:   "der Erwerbstätigen können nicht allein über Stellenanzeigen erreicht werden",
						},
						infobox:    {
							title1: "Schneller, effizienter, Spotted",
							body1:  "Wir haben Zugriff auf den grössten Talentpool der Schweiz. Innerhalb weniger Tage liefern wir dir qualifizierte und interessierte Bewerber. Beschleunige deine Time to Hire.",
							title2: "Kostengünstiger durch Technologie",
							body2:  "Unsere Recruiter sind Experten der digitalen Rekrutierung und profitieren von neuesten Matching-Technologien. Spare interne Ressourcen und optimiere deine Kosten.",
							title3: "Sorglos und transparent",
							body3:  "Geniesse professionelle Unterstützung im Active Sourcing. Unsere Recruiter betreuen dich ganz persönlich und sprechen die Talente aktiv an, egal wo sie sich befinden.",
						},
					},
					quotes:      [
						{
							quote: "Für unsere Matchmaker steht das Interesse der Kandidaten und Kunden an erster Stelle. Wir vermitteln nur, wenn ein beidseitiger Match gegeben ist.",
							name:  "Claudine Racine",
							job:   "Team Lead Recruiting"
						},
						{
							quote: "Unsere KI-gesteuerte Sourcing-Technologie stellt sicher, dass wir unsere Suchen ausweiten und so auch Kandidaten erreichen, die im traditionellen Ansatz nicht identifiziert würden. Auf diese Weise können wir Kandidaten auch Karrieremöglichkeiten bieten, die neue Wachstumschancen eröffnen.",
							name:  "Patrick Bär",
							job:   "Talent Acquisition Specialist"
						}
					],
					pricing:     {
						title:     "Perfekt ergänzt:<br>Active Sourcing vs. Active Sourcing PRO",
						body:      "Entdecke den passenden Service für deine Talentakquise – von effizienter Direktansprache bis hin zu umfassendem, personalisiertem Recruiting.",
						button:    "Jetzt kontaktieren",
						products:  ["Active Sourcing", "Active Sourcing PRO"],
						features:  [{
							label:  "Geeignete Positionen",
							values: ["Alle ausser IT, Tech, Management und Industrie", "IT, Tech, Management und Industrie"]
						}, {
							label:  "Zugang zum grössten Blue und Grey-Collar Talentpool der Schweiz mit aktiv und passiv suchenden Talenten",
							values: [true, true]
						}, {
							label:  "Reichweite der Suche<sup>1</sup>",
							values: ["JobCloud Eco&shy;system inklusive regionaler Partner", "+ Multi&shy;channel Active Sourcing"]
						}, {
							label:  "Maschinell gestütztes Matching<sup>2</sup> & Vorauswahl",
							values: [true, true]
						}, {
							label:  "Kandidaten&shy;selektion durch persönliche Recruiter",
							values: [true, true]
						}, {
							label:  "Kandidaten&shy;prüfung",
							values: [true, true]
						}, {
							label:  "Erweiterte Prüfung (Referenzen etc.)",
							values: ["Min. 3 abgestimmte Kriterien", "Umfang&shy;reiche Prüfung, Detaillierte Interviews"]
						}, {
							label:  "Erstellung komplettes Bewerber&shy;dossier",
							values: [false, true]
						}, {
							label:  "Enge Begleitung bis zur Anstellung",
							values: [false, true]
						}, {
							label:  "Such&shy;laufzeit",
							values: ["Gemäss Absprache", "45 Tage"]
						}, {
							label:  "Such&shy;gebühr",
							values: [false, "CHF 2’000"]
						}, {
							label:  "Erfolgs&shy;gebühr",
							values: ["Pro qualifiziertem Kandidat CHF 990", "Einstellungs&shy;gebühr 12/20% vom Jahres&shy;einkommen<sup>3</sup>"]
						}, {
							label:  "Mengen&shy;rabatt für 3 / 5 / 10 Aufträge",
							values: ["-5% / -10% / -20%", "-5% / -10% / -20%"]
						}],
						footnotes: [
							"Bei Bedarf: Social Media Kampagne auf Meta, Google und/oder YouTube zu zusätzlichen Kosten.",
							"Spotted nutzt eine im Hause entwickelte Lösung zum Matching auf Basis von Skills.",
							"Die Höhe der Erfolgsgebühr ist abhängig von der zu besetzenden Stelle. Verrechnung der Erfolgsgebühr von 12% resp. 20% (IT, Tech & Kader) nur bei Erfolg der Suche und Einstellung."
						]
					},
					contact:     {
						title:  "Kontaktiere uns für eine Demo",
						body:   "Trete mit uns in Kontakt und wir bringen dir Spotted näher. Gerne erstellen wir ein massgeschneidertes Angebot für dich.",
						button: "Formular absenden",
					},
				},
				team:            {
					section1: {
						title: "Wir sind deine Matchmaker",
						body:  "<p>Wir sind Spotted – deine digitalen Recruiting und Sourcing Experten. Wir nutzen künstliche Intelligenz und menschliches Know-How um Mitarbeitende und Unternehmen zum besten Match zu begleiten.</p><p>Spotted wurde 2020 innerhalb von JobCloud gegründet und wächst seitdem erstaunlich schnell. Unser junges, talentiertes Team besteht aus erfahrenen Recruitern, kreativen Marketeers, passionierten Engineers, kundenorientierten Sales Profis und einem flachen, kollaborativen Management.</p>",
					},
					contact:  {
						title:  "Wir freuen uns von dir zu hören",
						body:   "Möchtest du dein Talent bei uns ausleben und mit uns die Rekrutierung neu revolutionieren? Dann lass uns bei einem Kaffee/Tee/Chai/Bier darüber sprechen, wo du uns weiterbringen kannst!",
						button: "Formular absenden",
					},
					members:  [
						{
							name:  "Marija",
							title: "Recruiting"
						},
						{
							name:  "Arno",
							title: "Recruiting"
						},
						{
							name:  "Bas",
							title: "Engineering"
						},
						{
							name:  "Claudine",
							title: "Recruiting"
						},
						{
							name:  "Emanuel",
							title: "Engineering"
						},
						{
							name:  "Hiromi",
							title: "Sales"
						},
						{
							name:  "Karina",
							title: "Recruiting"
						},
						{
							name:  "Martin",
							title: "Product"
						},
						{
							name:  "Maria",
							title: "Social Media"
						},
						{
							name:  "Michal",
							title: "Engineering"
						},
						{
							name:  "Petra",
							title: "People & Culture"
						},
						{
							name:  "Samuel",
							title: "Engineering"
						},
						{
							name:  "Sebastian",
							title: "Management"
						},
						{
							name:  "Tal",
							title: "Management"
						},
						{
							name:  "Vlora",
							title: "Recruiting"
						},
						{
							name:  "Anette",
							title: "Sales"
						},
						{
							name:  "Isabell",
							title: "Recruiting"
						},
						{
							name:  "Marvin",
							title: "Sales"
						},
						{
							name:  "Patrick",
							title: "Recruiting"
						},
						{
							name:  "Paulina",
							title: "Sales"
						},
						{
							name:  "Romina",
							title: "Recruiting"
						},
						{
							name:  "Tamara",
							title: "Recruiting"
						},
						{
							name:  "Christoph",
							title: "Sales"
						},
						{
							name:  "Katharina",
							title: "Recruiting"
						},
						{
							name:  "Armando",
							title: "Recruiting"
						},
					],
				},
			},
		},
	},
};

export default translations;
