import { Card, CardContent, Typography } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { withTranslation } from 'react-i18next';
import { ACCEPTED_MIME_TYPES } from '../helpers/Constants';
import { FieldValidation } from '../helpers/ValidationHelpers';
import { DefaultProps } from '../pages/App/App';


interface Props extends DefaultProps {
	id?: string
	name: string
	label?: string
	required: boolean
	value?: File
	onChange: any
	validation?: FieldValidation
}

interface State {
}

class FormDropZone extends React.Component<Props, State> {

	updateValue = (files: any) => {
		this.props.onChange(files);
	};

	private static fileSizeSI = (bytes: any) => {
		let exp = Math.log(bytes) / Math.log(1024) | 0;
		let result = (bytes / Math.pow(1024, exp)).toFixed(2);
		return result + ' ' + (exp === 0 ? 'bytes' : 'KMGTPEZY'[exp - 1] + 'B');
	};

	render() {
		const {t, name, value, validation} = this.props;

		const hasError = (!!(validation && validation.error));
		const error = (validation && validation.error ? t(validation.error!) : " ");

		const id = (this.props.id ? this.props.id : name);
		const label = (this.props.label ? this.props.label : name);

		return (
			<div className="dropzone">
				{value !== undefined &&
				<Card elevation={0} className="text-justify mb-3 dz-selected">
					<CardContent sx={{margin: 0, padding: 0}}>
						<Typography variant="caption">{t('apply_form.selected_file')}</Typography>
						<Typography variant="h6">{value!.name} ({FormDropZone.fileSizeSI(value!.size)})</Typography>
					</CardContent>
				</Card>
				}
				<Dropzone onDrop={this.updateValue.bind(this)} accept={ACCEPTED_MIME_TYPES} multiple={false}>
					{({getRootProps, getInputProps}) => (
						<div {...getRootProps()} className={hasError ? "error" : ""}>
							<input {...getInputProps()} id={id} name={name} />
							<div className="dz-message">
								<Typography className="dx-text" sx={{padding: {lg: "30px 210px", md:"30px 120px", sm: "30px 60px", xs: "30px"} }}>
									{t(label)}
								</Typography>
							</div>
						</div>
					)}
				</Dropzone>
				{hasError && <FormHelperText className="text-danger">{error}</FormHelperText>}
			</div>
		);
	}
}

export default withTranslation('proactive')(FormDropZone);
